import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import formatDate from "../../../components/Control/formatDate";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableReadEditCell from "../../../components/TableEditCell";
import { useQuery } from "../../../components/Control/useQuery";
import { Save } from "@material-ui/icons";
import { useAuth } from "../../../context/GlobalContext";
import { DatePicker } from "@material-ui/pickers";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }
    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }
`;

const style1 = { border: "1px solid black", textAlign: "center" };

const row1 = [
  {
    machine_no: "PDC-01",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-02",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-03",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-04",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-05",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-06",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-07",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-08",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-09",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PDC-10",
    machine_name: "Pressure Die Casting",
    change_item: "",
  },
  {
    machine_no: "PP-01",
    machine_name: "Power Press",
    change_item: "",
  },
  {
    machine_no: "PP-02",
    machine_name: "Power Press",
    change_item: "",
  },
  {
    machine_no: "PP-03",
    machine_name: "Power Press",
    change_item: "",
  },
  {
    machine_no: "PP-04",
    machine_name: "Power Press",
    change_item: "",
  },
  {
    machine_no: "PP-05",
    machine_name: "Power Press",
    change_item: "",
  },
  {
    machine_no: "BRR-01",
    machine_name: "Broaching M/C",
    change_item: "",
  },
  {
    machine_no: "HPRS-01",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
];

const row2 = [
  {
    machine_no: "HPRS-02",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPRS-03",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPRS-04",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPRS-05",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPRS-06",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPFT-01",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPFT-02",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPFT-03",
    machine_name: "Hydraulic Press",
    change_item: "",
  },

  {
    machine_no: "HPSS-01",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPSS-02",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "HPSS-03",
    machine_name: "Hydraulic Press",
    change_item: "",
  },
  {
    machine_no: "LANF-01",
    machine_name: "Lancer",
    change_item: "",
  },
  {
    machine_no: "LANF-02",
    machine_name: "Lancer",
    change_item: "",
  },
  {
    machine_no: "LANF-03",
    machine_name: "Lancer",
    change_item: "",
  },
  {
    machine_no: "LANF-04",
    machine_name: "Lancer",
    change_item: "",
  },
  {
    machine_no: "SB-01, 02",
    machine_name: "Shot Blasting Machine",
    change_item: "",
  },
  {
    machine_no: "DFH-01",
    machine_name: "Decarb Furnace",
    change_item: "",
  },
];

function ViewRequestForm() {
  const { id, partId } = useParams();
  const query = useQuery();
  const unit = query.get("unit");
  console.log(unit);
  const history = useHistory();
  const { state } = useAuth();
  const isView = query.get("isView") === "true" ? true : false;
  const [data, setData] = useState({
    request_date: new Date(),
    unit: unit,
  });
  const [render, setRender] = useState(false);
  const [processList, setProcessList] = useState([]);

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios
          .get(`/api/get/request_form/${id}`)
          .catch((err) => console.log(err));
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    if (partId) {
      axios
        .get(`/api/getAllProcess/${partId}`)
        .then((res) => {
          setProcessList(res.data);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = (isSubmitted) => {
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Request Form?"
    );
    if (!isSubmit) {
      return;
    }
    data.is_request_submitted = isSubmitted;
    data.part_id = partId;
    if (isSubmitted) {
      data.is_admin_request = "pending";
      data.admin_request_no = 1;
    }
    data.request_prepared_by = state.user.name;
    if (id) {
      axios
        .put(`/api/edit/request_form/${id}`, data)
        .then((res) => {
          if (res.data.status === "success") {
            alert("document updated successfully");
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/create/request_form`, data)
        .then((res) => {
          if (res.data.status === "success") {
            alert("document updated successfully");
            history.goBack();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  function MouseOver(event) {
    event.target.style.transform = "scale(1.2)";
    event.target.style.transition = "200ms";
    event.target.style.border = "1px solid #ced4da";
    event.target.style.borderRadius = "5px";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
  }
  function MouseOut(event) {
    event.target.style.transform = "scale(1)";
    event.target.style.border = "none";
    event.target.style.boxShadow = "none";
    event.target.style.transition = "200ms";
  }
  function MouseDown(event) {
    event.target.style.transform = "scale(1.1)";
    event.target.style.transition = "300ms";
    event.target.style.border = "1px solid #868e96";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
  }

  const handleCheckBox = (item, changeType) => {
    return isView ? (
      item?.machine_no === data.machine_no &&
        data.change_item === changeType &&
        "✓"
    ) : (
      <span
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        onMouseDown={MouseDown}
        style={{
          fontSize: "1rem",
          display: "flex",
          outline: "none",
          alignItems: "center",
          justifyContent: "center",
          color: "#495057",
          backgroundColor: "#f1f3f5",
          boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
          cursor: "pointer",
          textShadow: "1px 1px #b0b0b0",
        }}
      >
        {item?.machine_no === data.machine_no &&
          data.change_item === changeType &&
          "✓"}
      </span>
    );
  };

  const handleDoubleClick = async (item, event, changeType) => {
    if (event.detail === 2 && !isView) {
      setData((prev) => ({ ...prev, ...item, change_item: changeType }));
    }
  };

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M REQUEST FORM
        </Typography>
        <Wrapper>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(false);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#003566",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Save & Continue
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(true);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#064a29",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Submit
          </Button>
        </Wrapper>
      </div>
      <Table cellSpacing="0" style={{ marginTop: "0.6rem" }} id="table-to-xls">
        <thead>
          <tr>
            <th
              colSpan={10}
              rowSpan="1"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.arcLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
          </tr>
          <tr>
            <th
              colSpan={10}
              align="center"
              style={{
                border: "1px solid black",
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              4M Change Summary Board
            </th>
          </tr>

          <tr>
            <th colSpan={2} style={style1}>
              DATE
            </th>
            <th colSpan={2} style={style1}>
              {isView ? (
                formatDate(data.request_date)
              ) : (
                <DatePicker
                  name="request_date"
                  format="dd/MM/yyyy"
                  variant="filled"
                  clearable
                  value={data?.request_date || null}
                  onChange={(date) => {
                    data.request_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th colSpan={2} style={style1}>
              Detail of Change
            </th>

            <th colSpan={2} style={style1}>
              NEW
              <TableReadEditCell
                name="detail_of_change_new"
                type="text"
                key="detail_of_change_new"
                style={{ width: "100%" }}
                value={data.detail_of_change_new}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={2} align="left" style={style1}>
              OLD
              <TableReadEditCell
                name="detail_of_change_old"
                type="text"
                key="detail_of_change_old"
                style={{ width: "100%" }}
                value={data.detail_of_change_old}
                {...{ handleChange, isView }}
              />
            </th>
          </tr>

          <tr>
            <th
              rowSpan={3}
              style={{ border: "1px solid black", textAlign: "center" }}
            >
              Machine No
            </th>
            <th rowSpan={3} style={style1}>
              Machine Name
            </th>
            <th rowSpan={1} colSpan={2} style={style1}>
              Change (✓, X)
            </th>
            <th rowSpan={3} style={style1}>
              Auth. Sign
            </th>
            <th rowSpan={3} style={style1}>
              Machine No
            </th>
            <th rowSpan={3} style={style1}>
              Machine Name
            </th>
            <th rowSpan={1} colSpan={2} style={style1}>
              Change (✓, X)
            </th>
            <th rowSpan={3} style={style1}>
              Auth. Sign
            </th>
          </tr>
          <tr>
            <th rowSpan={1} style={style1}>
              Man
            </th>
            <th rowSpan={1} style={style1}>
              M/c
            </th>
            <th rowSpan={1} style={style1}>
              Man
            </th>
            <th rowSpan={1} style={style1}>
              M/c
            </th>
          </tr>
          <tr>
            <th rowSpan={1} style={style1}>
              Mate.
            </th>
            <th rowSpan={1} style={style1}>
              Method
            </th>
            <th rowSpan={1} style={style1}>
              Mate.
            </th>
            <th rowSpan={1} style={style1}>
              Method
            </th>
          </tr>
        </thead>

        <tbody className="tablebody-row">
          {row1.map((item, index) => (
            <>
              <tr key={item.change_item}>
                <td rowSpan={2}>{item.machine_no}</td>
                <td rowSpan={2}>{item.machine_name}</td>

                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(item, e, "Man")}
                >
                  {handleCheckBox(item, "Man")}
                </td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(item, e, "M/c")}
                >
                  {handleCheckBox(item, "M/c")}
                </td>
                {index == 0 && <td rowSpan={row1.length * 2}></td>}

                <td rowSpan={2}>{row2[index]?.machine_no}</td>
                <td rowSpan={2}>{row2[index]?.machine_name}</td>

                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(row2[index], e, "Man")}
                >
                  {handleCheckBox(row2[index], "Man")}
                </td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(row2[index], e, "M/c")}
                >
                  {handleCheckBox(row2[index], "M/c")}
                </td>

                {index == 0 && <td rowSpan={row1.length * 2}></td>}
              </tr>

              <tr>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(item, e, "Mate")}
                >
                  {handleCheckBox(item, "Mate")}
                </td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(item, e, "Method")}
                >
                  {handleCheckBox(item, "Method")}
                </td>

                {/* row 2 */}
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(row2[index], e, "Mate")}
                >
                  {handleCheckBox(row2[index], "Mate")}
                </td>
                <td
                  style={{
                    padding: "0px",
                    margin: "0px",
                    height: "30px",
                    width: "100px",
                    backgroundColor: "#f1f3f5",
                  }}
                  onClick={(e) => handleDoubleClick(row2[index], e, "Method")}
                >
                  {handleCheckBox(row2[index], "Method")}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ViewRequestForm;
