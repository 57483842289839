import React, { useState, useRef } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import { DatePicker } from "@material-ui/pickers";
import DescriptionIcon from "@material-ui/icons/Description";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
// import formatDate from "/../../components/Control/formatDate";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import HeaderOfSkillMatrix from "./HeaderOfSkillMatrix";
import { Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import formatDate from "../../../components/Control/formatDate";

const SkillMatrixList = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [isSelectSkillMatrixHeader, setIsSelectSkillMatrixHeader] =
    useState(false);
  const skillMatrixId = useRef(null);
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );
  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllSkillMatrix?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    isSelectSkillMatrixHeader,
  ]);
  const handleDelete = async (id) => {
    const ans = await confirmDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/deleteSkillMatrix/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          SKILL MATRIX LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsSelectSkillMatrixHeader(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Skill Matrix
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "1" }}>
              <Input
                placeholder="Search By Title"
                variant="filled"
                style={{
                  width: "97%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Department
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Review Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Next Review
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Edit Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{item?.title}</TableCell>
                      <TableCell align="center">
                        {item?.department_id?.department_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "12rem",
                          width: "12rem",
                        }}
                      >
                        {formatDate(item?.reviewed_on_date)}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item?.next_reviewed_on_date)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          width: "15rem",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            background: "#003566",
                            color: "#fafafa",
                            maxWidth: "15rem",
                          }}
                          startIcon={<DescriptionIcon />}
                          onClick={() => {
                            skillMatrixId.current = item._id;
                            setIsSelectSkillMatrixHeader(true);
                          }}
                        >
                          Edit Matrix Header
                        </Button>
                        {/* </Link> */}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="Edit" arrow>
                          <Link to={`/createEditViewSkillMatrix/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>

                        <Tooltip title="view Skill Matrix" arrow>
                          <Link
                            to={`/createEditViewSkillMatrix/${item._id}?isView=true`}
                          >
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.5rem",
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  marginLeft: "0.5rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={"create new Skill Matrix"}
          openPopup={isSelectSkillMatrixHeader}
          closeModal={() => {
            skillMatrixId.current = null;
            setIsSelectSkillMatrixHeader(false);
          }}
          backgroundColor="white"
        >
          <HeaderOfSkillMatrix
            setIsSelectSkillMatrixHeader={setIsSelectSkillMatrixHeader}
            id={skillMatrixId.current}
          />
        </Modal>

        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default SkillMatrixList;
