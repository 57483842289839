import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import { Add, Remove } from "@material-ui/icons";
import PublishIcon from "@material-ui/icons/Publish";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import axios from "axios";

import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { DivStyled, InputStyled, GridfourStyled } from "./topicPaperStyle";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";

const CreateEditTopicPaper = () => {
  const history = useHistory();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [addTopic, setAddTopic] = useState({
    topics: [],
  });
  const [expandState, setExpandState] = useState([]);

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    "DELETE QUESTION",
    "Are you sure you want to delete this Question?"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneTopicPaper/${id}`)
        .then((res) => {
          setAddTopic(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handelSubmit = async () => {
    let ans = await confirmUpdate();
    if (!ans) return;
    axios
      .put(`/api/updateTopicPaper/${id}`, addTopic)
      .then((res) => {
        window.alert("success");
      })
      .catch((err) => console.log(err));
  };

  const sortQuestionPaper = (e, index) => {
    addTopic.topics.s_no = +e.target.value;
    addTopic.topics.sort((a, b) => Number(a.s_no) - Number(b.s_no));
    setRender((prev) => !prev);
  };

  const removeProcess = async (index) => {
    let ans = await confirmDeleteProcess();
    if (!ans) return;
    addTopic.topics.splice(index, 1);
    setExpandState((prev) => {
      prev[index] = false;
      return [...prev];
    });
  };

  return (
    <main style={{ paddingBottom: "4rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",

          alignItems: "center",
        }}
      >
        ADD EDIT QUESTION PAPER
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              startIcon={<PublishIcon />}
              onClick={handelSubmit}
              style={{
                backgroundColor: "#054A29",
                marginRight: "0.5rem",
                color: "#fff",
              }}
            >
              Save and continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>

      <section style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202", marginTop: "1rem" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>Question</span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => {
                addTopic &&
                  addTopic?.topics.push({
                    s_no: addTopic?.topics.length + 1,
                    name: "",
                    type: "",
                  });
                setExpandState((prev) => {
                  prev[addTopic?.topics.length - 1] = true;
                  return [...prev];
                });
              }}
              justify="center"
            >
              <Add />
              <span>Add Topic</span>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        {addTopic &&
          addTopic?.topics.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
              }}
            >
              <div
                key={process?.key}
                style={{
                  marginBottom: "0.3rem",
                  marginTop: "0.6rem",
                  padding: "0rem",
                  border: "1px solid #f0f2f0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#fafafa",
                    alignItems: "center",
                  }}
                  className="spec-header"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    <Wrapper>
                      <Input
                        type="Number"
                        name="s_no"
                        value={item.s_no}
                        onChange={(e) => sortQuestionPaper(e, index)}
                        style={{
                          width: "70px",
                        }}
                      />
                    </Wrapper>

                    <div
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {item?.name}
                    </div>
                  </div>

                  <Wrapper>
                    {expandState[index] ? (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "130px",
                          backgroundColor: "#003566",
                          cursor: "pointer",
                          color: "white",
                          alignItems: "center",
                          gap: "0.5rem",
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index] = false;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandLess style={{ marginLeft: "0.5rem" }} />
                        <span>Collapse</span>
                      </Wrapper>
                    ) : (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "130px",
                          backgroundColor: "#003566",
                          cursor: "pointer",
                          color: "white",
                          gap: "0.5rem",
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index] = true;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandMore style={{ marginLeft: "0.5rem" }} />
                        <span>Expand</span>
                      </Wrapper>
                    )}

                    <Wrapper
                      style={{
                        height: "45px",
                        width: "45px",
                        // backgroundColor: " #212529",
                        color: "#f1f3f5",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.5rem",
                        backgroundColor: "#D70012",
                      }}
                      justify="center"
                      onClick={() => removeProcess(index)}
                    >
                      <DeleteIcon style={{ color: "#f8f9fa" }} />
                    </Wrapper>
                  </Wrapper>
                </div>
              </div>
              {expandState[index] && (
                <DivStyled>
                  <span>
                    <Select
                      name={"topic_type"}
                      variant="filled"
                      style={{
                        fontSize: "1rem",
                        width: "200px",
                      }}
                      value={item.topic_type}
                      disableUnderline
                      onChange={(e) => {
                        item[e.target.name] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    >
                      <MenuItem>None</MenuItem>
                      <MenuItem value="L1">L1</MenuItem>
                      <MenuItem value="L2">L2</MenuItem>
                      <MenuItem value="L3">L3</MenuItem>
                      <MenuItem value="L4">L4</MenuItem>
                    </Select>
                  </span>
                  <span>
                    <InputStyled
                      style={{ height: "60px" }}
                      width="70%"
                      type="text"
                      name="name"
                      placeholder="Topic"
                      defaultValue={item?.name}
                      onBlur={(e) => {
                        item[e.target.name] = e.target.value;
                      }}
                    />
                  </span>
                </DivStyled>
              )}
            </div>
          ))}
      </section>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <DialogDeleteProcess isSubmitContent={false} />
    </main>
  );
};

export default CreateEditTopicPaper;
