import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { images, textInputs } from "../../constants/constants";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { ButtonStyled, TableContainerStyled } from "../DailyProduction/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditInstrument from "./AddEditInstrument";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
// import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import formatDate from "../../components/Control/formatDate";
import { useHistory } from "react-router-dom";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import TableToExcel from "@dayalk/table-to-excel";
import { useAuth } from "../../context/GlobalContext";

const InsrumentsList = () => {
  const [data, setData] = useState([]);
  const [isNewInstrumentModal, setIsNewInstrumentModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("HEIGHT GAUGE");
  const [instrumentId, setInstrumentId] = useState(null);
  const history = useHistory();

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this supplier ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/instrument?page=${page}&search=${search}&filter=${filter}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    // duplicateOpenModal,
    isNewInstrumentModal,
  ]);

  async function deleteInstrument(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/delete_instrument/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  function calculateDueDate(date, freq) {
    if (
      date === undefined ||
      date === null ||
      date === " " ||
      date === "" ||
      freq === "" ||
      freq === 0 ||
      freq === undefined
    ) {
      return "";
    }

    const freqInMonth = Math.ceil(freq * 12);

    let calcDate = new Date(date);

    const dueDateMili = calcDate.setMonth(
      calcDate.getMonth() + freqInMonth,
      calcDate.getDate() - 1
    );

    const dueDate = new Date(dueDateMili);
    console.log(dueDate);
    return dueDate;
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          INSTRUMENT LIST
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#003566",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<AddCircleIcon />}
              onClick={() => {
                history.push("/miscDocumentsDrawings/table");
              }}
            >
              Upload TC
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setInstrumentId(null);
                setIsNewInstrumentModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Instrument
            </Button>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("machinetable"),
                      {
                        name: "Instrument List.xlsx",
                        sheet: {
                          name: "Instrument List",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Instrument"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{
              marginRight: "1.5rem",
              marginTop: "0.8rem",
              flex: "0.2",
            }}
          >
            <InputLabel>Instrument Type</InputLabel>
            <Select
              // name="instrument_type"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              {[
                "HEIGHT GAUGE",
                "MAGNETIC V-BLOCK",
                "SURFACE PLATE",
                "COMPARATOR STAND",
                "VERNIER CALIPER",
                "LEVER DIAL",
                "PLUNGER DIAL",
                "MICROMETER",
                "COORDINATE MEASURING MACHINE",
                "HARDNESS TESTER",
                "COATING THICKNESS TESTER",
                "PROFILE PROJECTOR",
                "SURFACE ROUGHNESS TESTER",
                "EYE PIECE",
                "BENCH CENTER",
                "WEIGHING MACHINE",
                "TECHOMETER",
                "SLIP GAUGE BLOCK",
                "PLUG GAUGE",
                "WEAR CHECK RING",
                "WEAR CHECK PLUG",
                "PLAIN RING GAUGE",
                "THREAD RING GAUGE",
                "THREAD PLUG GAUGE",
                "SNAP GAUGE",
                "SPECIAL GUAGE",
                "PRESSURE GAUGE",
                "CONTOUR",
                "ROUNDNESS MACHINE TESTER",
                "BORE GAUGE",
                "MICROSCOPIC TESTER",
                "CONTOUR",
                "HEX PLUG GAUGE",
                "AIR PLUG  GAUGE / AIR GAUGE UNIT",
                "THICKNESS PLATING TESTER",
                "RADIUS GAUGE",
                "LUX METER",
                "MASTER RING GAUGE",
                "MASTER PLUG GAUGE",
                "AIR PLUG GAUGE",
              ].map((type, index) => (
                <MenuItem value={type} key={index + 200.0202}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Wrapper>
        <TableContainer component={Paper}>
          <TableContainerStyled>
            <Table
              size="small"
              data-default-wrap="true"
              id="machinetable"
              data-cols-width="15, 15, 15, 20, 15, 18, 15, 15, 15, 15, 15, 20, 20, 20"
            >
              <thead style={{ display: "none" }}>
                <tr style={{ height: "70px" }}>
                  <th
                    colSpan="14"
                    align="left"
                    style={{
                      border: "1px solid black",
                      fontSize: "1.6rem",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    <CenteredLogoImg>
                      <img
                        src={images.arcLogo}
                        alt={textInputs.companyName}
                        height="46"
                        width="120"
                      />
                      <span>{textInputs.companyName}</span>
                    </CenteredLogoImg>
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    DOC. NO:- ARC/F/QAD/101
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan="14"
                    align="center"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      backgroundColor: "#1d1d1d",
                      color: "white",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    INSTRUMENTS LIST
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    REV NO./DATE :- 01/29.09.2022
                  </th>
                </tr>
              </thead>
              <TableHead>
                <TableRow style={{ padding: "6px" }}>
                  {[
                    "S.NO",
                    "INSTRUMENT TYPE",
                    "DESCRIPTION",
                    "ID NO.",
                    "FREQUENCY (IN YEAR)",
                    "AGENCY",
                    "CALIBRATION DONE DATE",
                    "CALIBRATION DUE DATE",
                    "Judgement",
                    "LOCATION",
                    "GENERATE MSA",
                    "ACTION",
                  ].map((heading, index) => (
                    <TableCell
                      key={index + 1.00001}
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                      align="center"
                    >
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {data && (
                <TableBody>
                  {data.map((item, index) => (
                    // <React.Fragment key={item._id}>
                    <TableRow key={item._id}>
                      <TableCell align="center">
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">
                        {item?.instrument_type}
                      </TableCell>
                      <TableCell align="center">{item?.description}</TableCell>
                      <TableCell align="center">{item?.id_no}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.frequency_in_year}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.agency}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {formatDate(item?.calibration_done_date)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          textTransform: "capitalize",
                          ...(new Date(
                            calculateDueDate(
                              item?.calibration_done_date,
                              +item?.frequency_in_year
                            )
                          ) < new Date() && {
                            background: "red",
                            color: "white",
                          }),
                        }}
                      >
                        {formatDate(
                          calculateDueDate(
                            item?.calibration_done_date,
                            +item?.frequency_in_year
                          )
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.judgement}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.location}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Tooltip title="MSA Report" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: ".5rem",
                            }}
                            onClick={() => {
                              history.push(
                                `/view/msaTable/${item._id}?instrumentName=${item.instrument_type}&idNo=${item.id_no}&lc=${item.l_c}`
                              );
                            }}
                          >
                            <Add
                              style={{ fontSize: "1.4rem", color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "150px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "150px",
                            width: "150px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Edit Machine" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setInstrumentId(item._id);
                                setIsNewInstrumentModal(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>

                          {state.user?.userType === 2 && (
                            <Tooltip title="Delete Machine" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={() => {
                                  deleteInstrument(item._id);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    // </React.Fragment>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainerStyled>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={instrumentId ? "update supplier" : "create new supplier"}
          openPopup={isNewInstrumentModal}
          closeModal={() => {
            setIsNewInstrumentModal(false);
            setInstrumentId(null);
          }}
          backgroundColor="white"
        >
          <AddEditInstrument
            id={instrumentId}
            {...{ setIsNewInstrumentModal }}
          />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default InsrumentsList;
