import React from "react";
import { Link } from "react-router-dom";
import tiles from "./tiles.module.css";
//icons
import BallotIcon from "@material-ui/icons/Ballot";
// import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import ListIcon from "@material-ui/icons/List";
import { useAuth } from "../../../context/GlobalContext";
import { useQuery } from "../../../components/Control/useQuery";

// end icons

const Tiles4M = () => {
  const { state } = useAuth();
  const userType =
    state.user && state?.user?.userType === 3 ? "supervisor" : "admin";
  const query = useQuery();
  const unit = query.get("unit");
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        4M Management Modules
      </div>
      <main className={`${tiles.container} ${tiles.main}`}>
        <div>
          <Link to={`/${userType}/view/4mTable?unit=${unit}`} className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            4M Change & <span className="card-btn-span">Setup Form</span>
          </p>
        </div>
        <div>
          <Link to={`/viewMonitoringList?unit=${unit}`} className="panel">
            <BallotIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            4M<span className="card-btn-span">Summary Sheet</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Tiles4M;
