import { React, useEffect, useRef, useState } from "react";
// import { DatePicker, TimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import { areaOfWork } from "../Constant.js";
import { DivStyled } from "../Container.styled.js";

const SelectDepartmentModal = ({ id, setIsSelectDepartmentModal }) => {
  const [skillEvaluationData, setSkillEvaluationData] = useState({
    levels: [
      {
        name: "1 level(Basic Training required)",
        symbol: "◔",
      },
      {
        name: "2 Level(Can work under supervision)",
        symbol: "◑",
      },
      {
        name: "3 Level(Can work Independently)",
        symbol: "◕",
      },
      {
        name: "4 Level(Can train others)",
        symbol: "⬤",
      },
    ],
  });
  const [departmentData, setDepartmentData] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [render, setRender] = useState(false);

  const handleInput = (e) => {
    skillEvaluationData[e.target.name] = e.target.value;
  };

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        setDepartmentData(res?.data?.result);
        // console.log(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getSkillEvaluation/${id}`, skillEvaluationData)
        .then((res) => {
          setSkillEvaluationData(res.data);
          setAutoCompleteValue({
            department_name: res?.data?.department_id?.department_name,
            _id: res.data?.department_id?._id,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handelSumbit = (e) => {
    if (id) {
      axios
        .patch(`/api/patchSkillEvaluation/${id}`, skillEvaluationData)
        .then((res) => {
          window.alert("success");
          setIsSelectDepartmentModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      // skillEvaluationData["levels"] = new Array(4).fill().map((_, index) => {
      //   return { levels_type: `Level_${index + 1}`, question: [] };
      // });

      axios
        .post(`/api/createSkillEvaluation`, skillEvaluationData)
        .then((res) => {
          window.alert("success");
          setIsSelectDepartmentModal(false);
        })
        .catch((err) => {
          // if (err.response.data.error.message.includes("E11000")) {
          //   window.alert("Can not create duplicate area of work");
          // }
          console.log(err);
        });
    }
  };
  // console.log(skillEvaluationData);

  // console.log(autoCompleteValue, departmentData);

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handelSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          defaultValue={skillEvaluationData?.title}
          onBlur={handleInput}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="department_id">Department Name</label>
        <Autocomplete
          id="department_id"
          options={departmentData}
          getOptionLabel={((option) => option.department_name) || []}
          value={autoCompleteValue}
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          onChange={(e, value) => {
            skillEvaluationData.department_id = value?._id;
            // console.log(value?._id);
            setAutoCompleteValue(value);
            setRender((prev) => !prev);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
        />
      </DivStyled>
    </section>
  );
};

export default SelectDepartmentModal;
