import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../DailyProduction/styles";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
import { MenuItem, Select } from "@material-ui/core";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;
// const dropdownStyles = makeStyles({
//   root: {
//     backgroundColor: "#fff",
//   },
//   select: {
//     "&:hover": {
//       backgroundColor: "#fff",
//     },
//     "&:focus": {
//       backgroundColor: "#fff",
//     },
//   },
//   underline: {
//     borderBottom: "0px solid red !important",
//     "&:hover": {
//       borderBottom: "0px solid rgba(0,0,0,0)",
//     },
//   },
// });

// symbol for current level
const selectSymbolForLevel = {
  L1: "◔",
  L2: "◑",
  L3: "◕",
  L4: "⬤",
};

const levelText = (text) => {
  return text.replace("_", " ");
};
const AddEditViewSkillMatrix = () => {
  // const classes = dropdownStyles();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [data, setData] = useState([]);
  const [skillMatrixData, setSkillMatrixData] = useState({
    remarks: {},
    employee_details: [],
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );
  // useEffect(() => {
  //   if (skillMatrixData.employee_details.length > 0) {
  //     axios
  //       .get(`/api/allEmployeeWithoutPagination/${id}`)
  //       .then((res) => {
  //         if (res.data) {
  //           const values = {...skillMatrixData}
  //             values.employee_details = res.data
  //           setData(res.data);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, []);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getSkillMatrix/${id}`)
      .then((res) => {
        setSkillMatrixData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        axios
          .put(`/api/updateSkillMatrix/${id}`, skillMatrixData)
          .then((res) => {
            alert("updated successfully");
          })
          .then(() => {
            if (bool) {
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  // console.log(skillMatrixData);
  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            SKILL MATRIX
          </Typography>
          {!isView && (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleSubmitAndSave(false);
                  }}
                >
                  Save & Continue
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          )}
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{
              margin: "1rem",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="2"
                  rowSpan={3}
                  align="left"
                  style={{
                    border: "1px solid black",
                    // fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO : F-HRD-08
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }}>REV NO.: 02</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }}>
                  REV DATE: 01-12-2019
                </th>
              </tr>
              <tr>
                <th
                  colSpan="3"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  SKILL MATRIX
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan={2}
                >
                  REVIEWED DATE :{formatDate(skillMatrixData?.reviewed_on_date)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                >
                  NEXT REV: {formatDate(skillMatrixData?.next_reviewed_on_date)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan={3}
                >
                  DEPT. NAME: {skillMatrixData?.department_id?.department_name}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan={3}
                >
                  NAME OF DEPT. HEAD : {skillMatrixData?.department_id?.hod}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {["S. NO.", "NAME", "Activities"].map((heading, index) => (
                  <th
                    key={index + 10.0002}
                    colSpan={1}
                    rowSpan={index === 2 ? 1 : 2}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              <tr>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  Turner & Die Fitter
                </th>
              </tr>
              {skillMatrixData &&
                skillMatrixData?.employee_details?.map((employee, index) => (
                  <tr key={employee._id || index + 30.0003}>
                    <th
                      style={{
                        width: "100px",
                        border: "1px solid black",
                        textAlign: "center",
                        padding: "0 0.4rem",
                        backgroundColor: "#f8f9fa",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "400px",
                      }}
                    >
                      {employee?.employee_id?.employee_name}
                    </td>

                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        fontSize: "1.6rem",
                      }}
                    >
                      {isView ? (
                        selectSymbolForLevel[employee.employee_id?.skill_level]
                      ) : (
                        <Select
                          name="part_type"
                          variant="filled"
                          style={{ width: "200px" }}
                          onChange={(e) => {
                            employee.skill_level = e.target.value;
                            setRender((prev) => !prev);
                          }}
                          value={
                            employee.skill_level ||
                            employee.employee_id?.skill_level ||
                            ""
                          }
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="L1">◔</MenuItem>
                          <MenuItem value="L2">◑</MenuItem>
                          <MenuItem value="L3">◕</MenuItem>
                          <MenuItem value="L4">⬤</MenuItem>
                        </Select>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tr style={{ height: "40px", border: "1px solid black" }}></tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                LEGENDS:-
              </td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
            </tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                ◔
              </td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                Trainee
              </td>
            </tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                ◑
              </td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                Semi skilled
              </td>
            </tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                ◕
              </td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                Skilled
              </td>
            </tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                ⬤
              </td>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                Highly skilled
              </td>
            </tr>
            <tr style={{ height: "40px", border: "1px solid black" }}></tr>
            <tr>
              <td
                align="center"
                style={{
                  border: "1px solid black",
                }}
              >
                नोट -
              </td>
              <td
                align="center"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                फिटर या टर्नर का स्किल लेवल कम से कम दो होना चाहिए
              </td>
            </tr>
          </Table>
        </TableContainerStyled>
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default AddEditViewSkillMatrix;
