const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const getDailyProductionYearly = (year) => {
  return months.map((month) => ({
    month_year: `${month}-${new Date(year).getFullYear()}`,
  }));
};

const MoniteringSheetTableHead = [
  {
    name: "Date",
    rowspan: "3",
  },
  {
    name: "Duration time",
    rowspan: "1",
    colspan: "2",
  },
  {
    name: "Total Mintus",
    rowspan: "3",
  },
  {
    name: "Reason of Change( Man,Machine, Material, Method)",
    rowspan: "3",
  },
  {
    name: "Change Type P/ UP/ ABN",
    rowspan: "3",
  },
  {
    name: "Part Name/No.",
    rowspan: "3",
  },
  {
    name: "Details of Change",
    rowspan: "1",
    colspan: "3",
  },
  {
    name: "Action taken",
    rowspan: "1",
    colspan: "4",
  },
  {
    name: "Retroactive",
    rowspan: "1",
    colspan: "3",
  },
  {
    name: "Suspected",
    rowspan: "1",
    colspan: "3",
  },
  {
    name: "Sig. Prod. Head",
    rowspan: "3",
  },
  {
    name: "Sig. QA inspector",
    rowspan: "3",
  },
  {
    name: "Sig. QA Head",
    rowspan: "3",
  },
  {
    name: "Sig.  Maint  Head",
    rowspan: "3",
  },
  {
    name: "Dispatch detail",
    rowspan: "1",
    colspan: "4",
  },
];

export { getDailyProductionYearly, MoniteringSheetTableHead };
