import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import ChecklistEditTable from "./ChecklistEditTable/ChecklistEditTable";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import useConfirm from "../../../../components/Control/useConfirm";
import {
  CNC,
  Air_Compressor,
  Bend_Shaw,
  Centr_Grinding,
  Centreless_Gr,
  CTR,
  Drill_Machine,
  Drill_Machine_Manual,
  Generator,
  HP,
  Hydrau,
  IH,
} from "./allmachineDATA";
import TranslateIcon from "@material-ui/icons/Translate";
import SaveIcon from "@material-ui/icons/Save";
import lodash from "lodash";
import { useQuery } from "../../../../components/Control/useQuery";
const tableType = lodash.cloneDeep({
  Air_Compressor: Air_Compressor,
  Bend_Shaw: Bend_Shaw,
  Centr_Grinding: Centr_Grinding,
  Centreless_Gr: Centreless_Gr,
  CTR: CTR,
  Drill_Machine: Drill_Machine,
  Drill_Machine_Manual: Drill_Machine_Manual,
  Generator: Generator,
  HP: HP,
  Hydrau: Hydrau,
  IH: IH,
  CNC: CNC,
});

const LinkingToChecklistComponent = () => {
  const { id, checklistType } = useParams();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;

  const history = useHistory();
  const [changeLanguage, setChangeLanguage] = useState("english");
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "Submit",
    `Are you sure you want to submit?`
  );
  const [DialogSave, confirmSave] = useConfirm(
    "Save For Later",
    `Are you sure you want to save?`
  );

  const [checkListMachineData, setCheckListMachineData] = useState({
    checkListMachineData: {
      Air_Compressor: [],
      Bend_Shaw: [],
      Centr_Grinding: [],
      Centreless_Gr: [],
      CNC: [],
      CTR: [],
      Drill_Machine: [],
      Drill_Machine_Manual: [],
      Generator: [],
      HP: [],
      Hydrau: [],
      IH: [],
    },
  });

  useEffect(() => {
    axios
      .get(`/api/getChecklistById/${id}`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          if (!data.document_name) {
            const values = { ...data };
            values.checkListMachineData[checklistType] =
              tableType[checklistType];
            setCheckListMachineData(values);
          }
          setCheckListMachineData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = async (status) => {
    if (status === "completed") {
      const ansSubmit = await confirmSubmit();
      if (ansSubmit) {
        checkListMachineData.document_name = checklistType;
        checkListMachineData.status = status;

        axios
          .put(`/api/updateChecklist/${id}`, checkListMachineData)
          .then((res) => {
            window.alert("successfully done ");
          })
          .then(() => {
            history.goBack();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (status === "started") {
      const ansSave = await confirmSave();
      if (ansSave) {
        checkListMachineData.document_name = checklistType;
        checkListMachineData.status = status;

        axios
          .put(`/api/updateChecklist/${id}`, checkListMachineData)
          .then((res) => {
            window.alert("successfully done ");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handleChecklist = (e, index) => {
    const values = { ...checkListMachineData };
    values.checkListMachineData[checklistType][index][e.target.name] =
      e.target.value;
    setCheckListMachineData(values);
  };

  return (
    <div>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        Checklist Of Machine Maintenance
        <Wrapper>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#1864ab",
                color: "#fafafa",
                marginRight: "0.5rem",
                width: "330px",
              }}
              startIcon={<TranslateIcon />}
              onClick={() =>
                setChangeLanguage((prev) =>
                  prev === "english" ? "hindi" : "english"
                )
              }
            >
              Change Lanuguage to{" "}
              {changeLanguage === "english" ? "hindi" : "english"}
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#003566",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
              onClick={(e) => handleSubmit("started")}
            >
              Save For Later
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUpload />}
              onClick={(e) => handleSubmit("completed")}
            >
              Submit
            </Button>
          </div>
        </Wrapper>
      </div>
      <ChecklistEditTable
        {...{
          checkListMachineData,
          handleChecklist,
          changeLanguage,
          checklistType,
          isView,
        }}
      />
      {checklistType === "None" && "NOT AVAILABLE "}

      <DialogSubmit isSubmitContent={false} />
      <DialogSave />
    </div>
  );
};

export default LinkingToChecklistComponent;
