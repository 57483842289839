import { React, useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DivStyled } from "../Container.styled.js";

const QuestionModal = ({ id, setIsQuestionModal }) => {
  const [topicPaper, setTopicPaper] = useState({});
  const [render, setRender] = useState(false);
  const [allDepartment, setAllDepartment] = useState([]);

  const handleInput = (e) => {
    topicPaper[e.target.name] = e.target.value;
  };

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        const data = res.data.result.map((item) => {
          return {
            _id: item._id,
            department_name: item.department_name,
          };
        });
        setAllDepartment(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneTopicPaper/${id}`, topicPaper)
        .then((res) => {
          setTopicPaper(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handelSumbit = (e) => {
    if (id) {
      axios
        .patch(`/api/patchTopicPaper/${id}`, topicPaper)
        .then((res) => {
          window.alert("success");
          setIsQuestionModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/createTopicPaper`, topicPaper)
        .then((res) => {
          window.alert("success");
          setIsQuestionModal(false);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes("E11000")) {
            window.alert("Can not create duplicate area of work");
          }
          console.log(err);
        });
    }
  };

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handelSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label for="topic_paper_name">Topic Paper Name</label>
        <input
          type="text"
          id="topic_paper_name"
          name="topic_paper_name"
          defaultValue={topicPaper?.topic_paper_name}
          onBlur={handleInput}
        />
      </DivStyled>
      <DivStyled>
        <label for="area_of_work">Department</label>
        <Autocomplete
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          id="training_topics"
          options={allDepartment}
          getOptionLabel={(option) => option.department_name}
          value={
            topicPaper.area_of_work_id || topicPaper.area_of_work || ""
          }
          onChange={(e, value) => {
            topicPaper.area_of_work = value._id;
            topicPaper.area_of_work_id = value;
            setRender((prev) => !prev);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </DivStyled>
    </section>
  );
};

export default QuestionModal;

