import React from "react";
import { Link } from "react-router-dom";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WorkIcon from "@material-ui/icons/Work";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import tilesStyle from "./tilesStyle.module.css";

import { GridContainer } from "./Container.styled";
import CastForEducation from "@material-ui/icons/CastForEducation";

const EducationAndTraining = () => {
  return (
    <section style={{ height: "90vh" }}>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        TRAINING
      </div>
      <main className={`${tilesStyle.container} ${tilesStyle.main}`}>
        <div>
          <Link to="/tniTiles" className="panel">
            <CastForEducation style={{ fontSize: "3rem", color: "#135089" }} />
          </Link>
          <p className="card-btn-para">
            TNI
            <span className="card-btn-span">
              (Training Need Identification)
            </span>
          </p>
        </div>
        <div>
          <Link to="/annualTrainingList/" className="panel">
            <DateRangeIcon style={{ fontSize: "3rem", color: "#495057" }} />
          </Link>
          <p className="card-btn-para">
            TRAINING
            <span className="card-btn-span">PLAN</span>
          </p>
        </div>

        <div>
          <Link to="/trainginStatusList" className="panel">
            <DateRangeIcon style={{ fontSize: "3rem", color: "#495057" }} />
          </Link>
          <p className="card-btn-para">
            TRAINING
            <span className="card-btn-span">STATUS</span>
          </p>
        </div>
      </main>
    </section>
  );
};

export default EducationAndTraining;
