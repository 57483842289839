import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { DatePicker } from "@material-ui/pickers";

import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from "../../../components/Control/formatDate";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { useRef } from "react";
import TnaCreateModal from "./TnaCreateModal";
import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const Maintenance = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isTnaModal, setIsTnaModal] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );

  useEffect(() => {
    axios
      .get(`/api/getTniReport/yearly/${selectedDate}`)
      .then((res) => {
        setData(res.data.getAllTni);
      })
      .catch((err) => console.log(err));
  }, [selectedDate, render, isTnaModal]);

  const deleteTna = async (id) => {
    const ans = await confirmDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/deleteTnaReport/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          TNI LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsTnaModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Plan
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <div style={{ display: "flex", margin: "1rem" }}>
            <DatePicker
              views={["year"]}
              label="Year"
              className="date-field"
              inputVariant="filled"
              value={selectedDate || null}
              onChange={setSelectedDate}
            />
          </div>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date Range{" "}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data.length && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.title}
                      </TableCell>
                      <TableCell align="center">
                        {getYearRange(item.created_year)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="Edit Tna List" arrow>
                          <Link to={`/createEditTNI/report/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete Tna" arrow>
                          <IconButton
                            size="small"
                            onClick={() => deleteTna(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Modal
          title={"create new tna"}
          openPopup={isTnaModal}
          closeModal={() => {
            setIsTnaModal(false);
          }}
          backgroundColor="white"
        >
          <TnaCreateModal setIsTnaModal={setIsTnaModal} />
        </Modal>
        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default Maintenance;
