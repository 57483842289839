import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { useAuth } from "../../../context/GlobalContext";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import SyncIcon from "@material-ui/icons/Sync";
import Loader from "../../../components/Control/Loader";
import { ButtonStyled, TableContainerStyled, Table } from "../Container.styled";
import formatDate from "../../../components/Control/formatDate";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const CreateEditTNI = () => {
  const classes = dropdownStyles();
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [allDepartment, setAllDepartment] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState("");

  const [tniData, setTniData] = useState("");
  const [tniTopics, setTniTopics] = useState([]);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const getTopicByDepartment = (departmentId) => {
    axios
      .get(`/api/getTopicByDepartment/${departmentId}`)
      .then((res) => {
        if (res.data) {
          if (res.data.topics.length) {
            setTniTopics(res.data?.topics);
          } else {
            setTniTopics([{ name: "Other" }]);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        const data = res.data.result.map((item) => {
          return {
            department_id: item._id,
            department_name: item.department_name,
          };
        });
        setAllDepartment(data);
        data.length > 0 && setAutocompleteData(data[0]);
        data.length > 0 && getTopicByDepartment(data[0].department_id);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (id && autocompleteData) {
      axios
        .get(`/api/getOneTni/report/${id}/${autocompleteData.department_id}`)
        .then((res) => {
          setTniData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, autocompleteData, isLoading]);

  const handleSaveAndContinue = (deparmentId) => {
    axios
      .put(
        `/api/updateTniReport/yealy/${id}/${deparmentId}`,
        tniData.departments
      )
      .then((res) => {
        window.alert("success");
      })
      .catch((err) => console.log(err));
  };

  const handleSyncTniReport = async () => {
    setIsloading(true);
    axios
      .get(`/api/syncTniReport/${tniData._id}`)
      .then((res) => {
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert("successfully updated");
        }, 1000);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };

  const TickCrossSelectField = (item, index) => {
    return (
      <FormControl
        style={{
          width: "75px",
          height: "75px",
          border: "none",
          display: "flex",
          alignItems: "center",
        }}
        className="select-check-wrong"
        fullWidth={true}
        variant="filled"
        classes={{
          root: classes.root,
        }}
      >
        <Select
          classes={{
            root: classes.root,
            select: classes.select,
          }}
          inputProps={{ IconComponent: () => null }}
          style={{ fontSize: "0.8rem", width: "85px" }}
          disableUnderline
          value={item.training_topics[index] || ""}
          onChange={(e) => {
            item.training_topics[index] = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          <MenuItem value="R">R</MenuItem>
          <MenuItem value="NR">NR</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 60;
    } else {
      document.getElementById("machinetable").scrollLeft -= 60;
    }
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      <ButtonStyled
        left
        onClick={(e) => {
          handleScroll("left");
        }}
      >
        <div>
          <ArrowBackIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      <ButtonStyled
        right
        onClick={(e) => {
          handleScroll("right");
        }}
      >
        <div>
          <ArrowForwardIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "100",
            top: "50%",
            right: "50%",
            transform: "translate(50%,-50%)",
          }}
        >
          {<Loader height={"100vh"} />}
        </div>
      ) : (
        ""
      )}
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          TRAINING NEED IDENTIFICATION REPORT
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#1864ab",
                marginRight: "0.5rem",
              }}
              startIcon={<SyncIcon />}
              onClick={() => {
                handleSyncTniReport(true);
              }}
            >
              Synch Employee
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleSaveAndContinue(tniData?.departments[0]?._id);
              }}
            >
              Save And Continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>

      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan={3 + tniTopics.length}
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No. F-HRD-15
              </th>
            </tr>
            <tr>
              <th style={{ bo5er: "1px solid black" }} data-f-sz="10">
                REV. NO.:- 02
              </th>
            </tr>
            <tr>
              <th
                colSpan={3 + tniTopics.length}
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                {`TRAINING NEED IDENTIFICATION (ASSOCIATE) ${getYearRange(
                  tniData.created_year
                )}`}
              </th>

              <th style={{ bo5er: "1px solid black" }} data-f-sz="10">
                REV DATE : 10.05.2023
              </th>
            </tr>

            <tr>
              <th>DEPARTMENT</th>
              <th>
                <Autocomplete
                  id="area_of_work"
                  disableClearable={true}
                  options={allDepartment}
                  getOptionLabel={(option) => option.department_name}
                  getOptionSelected={(option, value) =>
                    option.department_name === value.department_name
                  }
                  value={autocompleteData}
                  onChange={(e, value) => {
                    // questionPaper.area_of_work = value.department_id;
                    setAutocompleteData(value);
                    getTopicByDepartment(value.department_id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </th>
              <th>YEAR:</th>
              <th colSpan={1 + tniTopics.length}>
                {formatDate(tniData.createdAt)}
              </th>
            </tr>
          </thead>
          <thead>
            {[
              "S.no.",
              "Employee Name",
              "Designation",
              "Training Topics",
              "Remarks",
            ].map((el, index) => (
              <th
                rowSpan={index === 3 ? 1 : 3}
                colSpan={index === 3 ? tniTopics.length : 1}
                align="center"
                style={{ textAlign: "center" }}
              >
                {el}
              </th>
            ))}
            {/* <tr> */}
            {/*   {[ */}
            {/*     "5S", */}
            {/*     "Tag Identification", */}
            {/*     "Fifo", */}
            {/*     "Safety", */}
            {/*     "Work Instruction", */}
            {/*     "Abnormal Situations", */}
            {/*     "4m Change", */}
            {/*     "Poka Yoke", */}
            {/*     "Rework Rule", */}
            {/*     "Kaizen", */}
            {/*     "Material", */}
            {/*   ].map((el, index) => ( */}
            {/*     <th align="center" style={{ textAlign: "center" }}> */}
            {/*       {el} */}
            {/*     </th> */}
            {/*   ))} */}
            {/* </tr> */}
            <tr>
              {tniTopics.map((topic, index) => (
                <th align="center" style={{ textAlign: "center" }}>
                  {topic.topic_type}
                </th>
              ))}
            </tr>
            <tr>
              {tniTopics.map((topic, index) => (
                <th align="center" style={{ textAlign: "center" }}>
                  {topic.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tniData?.departments?.length &&
              tniData.departments[0].employeelist.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      {item?.employee_id?.employee_name}
                    </td>
                    <td>{item?.employee_id?.designation}</td>
                    {/* <td align="center"> */}
                    {/*   <input */}
                    {/*     autoComplete="off" */}
                    {/*     className="input" */}
                    {/*     name="training_topic" */}
                    {/*     style={{ */}
                    {/*       border: "1px solid #f9e0e0", */}
                    {/*       minWidth: "65px", */}
                    {/*     }} */}
                    {/*     type="textfield" */}
                    {/*     key={autocompleteData.department_id} */}
                    {/*     defaultValue={item.training_topic} */}
                    {/*     onChange={(e) => { */}
                    {/*       item.training_topic = e.target.value; */}
                    {/*       setRender((prev) => !prev); */}
                    {/*     }} */}
                    {/*   /> */}
                    {/* </td> */}
                    {tniTopics.map((topic, index) => (
                      <td>{TickCrossSelectField(item, index)}</td>
                    ))}
                    {/* <td>{TickCrossSelectField(item, "tag_identification")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "fifo")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "safety")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "work_Instruction")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "abnormal_situations")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "four_m_Change")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "poka_yoke")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "rework_rule")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "kaizen")}</td> */}
                    {/* <td>{TickCrossSelectField(item, "material")}</td> */}
                    <td align="center">
                      <input
                        autoComplete="off"
                        className="input"
                        name="remarks"
                        style={{
                          border: "1px solid #f9e0e0",
                          minWidth: "65px",
                        }}
                        type="textfield"
                        value={item.remarks || ""}
                        onChange={(e) => {
                          item.remarks = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </TableContainerStyled>
      <DialogClose isSubmitContent={false} />
    </div>
  );
};

export default CreateEditTNI;
