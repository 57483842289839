export const Air_Compressor = [
  {
    check_point_area_english: "Belt Condition",
    check_point_area_hindi: "बेल्ट की कंडिशन",
    checking_method: "Visual",
    judgement_criteria_english: "No Crack / No Damage ",
    judgement_criteria_hindi: "नो क्रेक व डेमेज ",
  },
  {
    check_point_area_english: "Belt Tension ",
    check_point_area_hindi: "बेल्ट टेंशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should Not be loose",
    judgement_criteria_hindi: "ढीली नही होनी चाहिए",
  },
  {
    check_point_area_english: "Condition Of Air Filter Element",
    check_point_area_hindi: "एयर फ़िल्टर एलिमंट की कंडिशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should not Be brocken & Dust free",
    judgement_criteria_hindi: "टूटी नही होनी चाहिए व क्रेक न हो",
  },
  {
    check_point_area_english: "Check All Electrical connections For Looseness",
    check_point_area_hindi: "इलेक्ट्रिक तारों के कनैक्शन",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए",
  },
  {
    check_point_area_english: "Automatic Cut off realy",
    check_point_area_hindi: "ऑटोमैटिक कट ऑफ रीले",
    checking_method: "Manualy",
    judgement_criteria_english: "Must Function Properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए",
  },
];

export const Bend_Shaw = [
  {
    check_point_area_english: "Check Proper Function Of the Limit Switch",
    check_point_area_hindi: "लिमिट स्विच के प्रोपर फंकशन चेक करें ",
    checking_method: "Manual",
    judgement_criteria_english: "Must work between Specified limit",
    judgement_criteria_hindi: "स्पेकिफी लिमिट में काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Belt Condition",
    check_point_area_hindi: "बेल्ट की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be Cracked & Damage",
    judgement_criteria_hindi: "क्रेक या डेमेज नही होना चाहिए ",
  },
  {
    check_point_area_english: "Check condition Of Chip Brush",
    check_point_area_hindi: "चिप ब्रुश की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be damage",
    judgement_criteria_hindi: "डेमेज नही होना चाहिए",
  },
  {
    check_point_area_english: "Check All Electrical connections For looseness",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning Of Electrical Panel",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Manual",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Check Bearing Condition",
    check_point_area_hindi: "बेयरिंग की कंडिशन",
    checking_method: "Visual",
    judgement_criteria_english:
      "No Cut Marks,No worn Out & Should be noise free",
    judgement_criteria_hindi:
      "कूट मार्क, वियर आउट नही होना चाहिए व असामान्य आवाज नही आनी चाहिए ",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual ",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "Part Counter",
    check_point_area_hindi: "पार्ट काउंटर ",
    checking_method: "Visual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
];
export const Centr_Grinding = [
  {
    check_point_area_english: "Work Head belt Condition",
    check_point_area_hindi: "वर्क हैड बेल्ट की कंडिशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be Cracked & Damage ",
    judgement_criteria_hindi: "क्रेक या डेमेज नही होना चाहिए ",
  },
  {
    check_point_area_english: "Work  head Belt Tension ",
    check_point_area_hindi: "वर्क हैड बेल्ट की टेंशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be Loose",
    judgement_criteria_hindi: "क्रेक या डेमेज नही होना चाहिए ",
  },
  {
    check_point_area_english: "Check All electrical Connection for looseness",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning of electrical Panel",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Bearing Condition",
    check_point_area_hindi: "बेयरिंग की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "No Cut Marks,No worn Out & Should be noise free",
    judgement_criteria_hindi:
      "कूट मार्क, वियर आउट नही होना चाहिए व असामान्य आवाज नही आनी चाहिए ",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "Warm gear condition",
    check_point_area_hindi: "Warm gear condition",
    checking_method: "Visual",
    judgement_criteria_english: "No worn Out",
    judgement_criteria_hindi: "No worn Out",
  },
  {
    check_point_area_english: "Lubrication oil level",
    check_point_area_hindi: "Lubrication oil level",
    checking_method: "Visual",
    judgement_criteria_english: "Oil level should be above red marking ",
    judgement_criteria_hindi: "Oil level should be above red marking ",
  },
  {
    check_point_area_english: "Chuk nut condition",
    check_point_area_hindi: "Chuk nut condition",
    checking_method: "Key",
    judgement_criteria_english: "Shoud not be loose",
    judgement_criteria_hindi: "Shoud not be loose",
  },
  {
    check_point_area_english: "Pump and impelller condition",
    check_point_area_hindi: "Pump and impelller condition",
    checking_method: "Visual",
    judgement_criteria_english: "Should work properly ",
    judgement_criteria_hindi: "Should work properly ",
  },
  {
    check_point_area_english: "Wheel balancing ",
    check_point_area_hindi: "Wheel balancing ",
    checking_method: "Fixture",
    judgement_criteria_english: "Shoud be balance",
    judgement_criteria_hindi: "Shoud be balance",
  },
  {
    check_point_area_english: "Shaft runout",
    check_point_area_hindi: "Shaft runout",
    checking_method: "Dial",
    judgement_criteria_english: "Less then 0.020",
    judgement_criteria_hindi: "Less then 0.020",
  },
];
export const Centreless_Gr = [
  {
    check_point_area_english: "ग्राईंडिंग व्हील हैड बेल्ट की कंडिशन",
    check_point_area_hindi: "ग्राईंडिंग व्हील हैड बेल्ट की कंडिशन",
    checking_method: "Visual",
    judgement_criteria_english: "क्रेक या डेमेज नही होना चाहिए ",
    judgement_criteria_hindi: "क्रेक या डेमेज नही होना चाहिए ",
  },
  {
    check_point_area_english: "ग्राईंडिंग व्हील हैड बेल्ट की टेंशन ",
    check_point_area_hindi: "ग्राईंडिंग व्हील हैड बेल्ट की टेंशन ",
    checking_method: "Visual",
    judgement_criteria_english: "क्रेक या डेमेज नही होना चाहिए ",
    judgement_criteria_hindi: "क्रेक या डेमेज नही होना चाहिए ",
  },
  {
    check_point_area_english: "इलैक्ट्रिक तारों के कनैक्शन ",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual",
    judgement_criteria_english: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "इलैक्ट्रिक  पैनल की सफाई",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Visual",
    judgement_criteria_english:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "बेयरिंग की कंडिशन ",
    check_point_area_hindi: "बेयरिंग की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "कूट मार्क, वियर आउट नही होना चाहिए व असामान्य आवाज नही आनी चाहिए ",
    judgement_criteria_hindi:
      "कूट मार्क, वियर आउट नही होना चाहिए व असामान्य आवाज नही आनी चाहिए ",
  },
  {
    check_point_area_english: "एमर्जेंसी स्विच",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "वार्म गियर की कंडिशन ",
    check_point_area_hindi: "वार्म गियर की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो गियर बदल  दें ",
    judgement_criteria_hindi:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो गियर बदल  दें ",
  },
  {
    check_point_area_english: "कंट्रोल व्हील बेयरिंग की कंडिशन ",
    check_point_area_hindi: "कंट्रोल व्हील बेयरिंग की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो व्हील बेयरिंग बदल  दें ",
    judgement_criteria_hindi:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो व्हील बेयरिंग बदल  दें ",
  },
  {
    check_point_area_english: "वर्म गैयर की कंडिशन ",
    check_point_area_hindi: "वर्म गैयर की कंडिशन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो वर्म गैयर बदल  दें ",
    judgement_criteria_hindi:
      "सही ढंग से काम करना चाहिए यदि नही कर रहा है तो वर्म गैयर बदल  दें ",
  },
];

export const CNC = [
  {
    check_point_area_english: "Temperature inside the control panel",
    check_point_area_hindi: "कंट्रोल पेनल का टेंपरेचर",
    checking_method: "Display Monitor",
    judgement_criteria_english: "25⁰- 40⁰C",
    judgement_criteria_hindi: "25⁰- 40⁰C",
  },
  {
    check_point_area_english:
      "Lubrication Pump Suction / Inlet Filter Cleaning.",
    check_point_area_hindi: "इन लेट फ़िल्टर की क्लीनिंग / लुब्रिकेशन पंप संकशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should befree from dust,Dirt",
    judgement_criteria_hindi: "धूल, गंदगी  न लगी हो, साफ होने चाहिए ",
  },
  {
    check_point_area_english: "Spindle Run Out",
    check_point_area_hindi: "स्पीण्डल रन आउट",
    checking_method: "Dial Stand +Lever Dial",
    judgement_criteria_english: "Max 0.03",
    judgement_criteria_hindi: "Max 0.03",
  },
  {
    check_point_area_english: "Chuck Runout",
    check_point_area_hindi: "चक  रन आउट",
    checking_method: "Dial Stand +Lever Dial",
    judgement_criteria_english: "Max 0.03",
    judgement_criteria_hindi: "Max 0.03",
  },
  {
    check_point_area_english: "Hydraulic Power Pack  Oil Level.",
    check_point_area_hindi: "स्हाईड्रोलिक पावर पेक ऑइल",
    checking_method: "Visual",
    judgement_criteria_english: "should be above Red Mark Level",
    judgement_criteria_hindi: "रेड मार्क से ऊपर होना चाहिए",
  },
  {
    check_point_area_english: "Cleaning Of Panel.",
    check_point_area_hindi: "पेनल की सफाई",
    checking_method: "Manual",
    judgement_criteria_english: "Should be free from dust,Dirt,Damage",
    judgement_criteria_hindi: "धूल मिट्टी न लगी हो, साफ होने चाहिए ",
  },
  {
    check_point_area_english: "Coolent Tank Cleaning",
    check_point_area_hindi: "कूलेंट टैंक की सफाई",
    checking_method: "Manual",
    judgement_criteria_english: "Should be free from dust,Dirt,Damage",
    judgement_criteria_hindi:
      "धूल, गंदगी  न लगी हो, साफ होने चाहिए व टूटे न हो ",
  },
  {
    check_point_area_english:
      "Check for any cable breakage / damage shielding.",
    check_point_area_hindi: "केबल टूटी हुई /शील्ड डेमेज",
    checking_method: "Visual",
    judgement_criteria_english: "No Breakage & damaged Shield ",
    judgement_criteria_hindi: "डेमेज या टूटी न हो",
  },
  {
    check_point_area_english: "Relay terminal screw tightening.",
    check_point_area_hindi: "रिले टेरमीनल स्क्रू टाइट",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be loose",
    judgement_criteria_hindi: "ढीली न हो ",
  },
  {
    check_point_area_english: "Coolant motor connector tightening.",
    check_point_area_hindi: "कूलेंट मोटर कनैक्टर टाइट",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be loose",
    judgement_criteria_hindi: "ढीली न हो ",
  },
  {
    check_point_area_english: "Servo motor connector tightening.",
    check_point_area_hindi: "सेरवों मोटर कनैक्टर टाइट ",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be loose",
    judgement_criteria_hindi: "ढीली न हो ",
  },
  {
    check_point_area_english:
      "Check if any abnormal noise / vibration / heat gener-ation in hyd. power pack and ball screw.",
    check_point_area_hindi:
      "किसी तरह की असामान्य आवाज/वाइब्रेशन / हीट जेनेरेशन इन पावर पेक ओर बाल स्क्रू",
    checking_method: "Visual",
    judgement_criteria_english:
      "No Abnormal Noise, No Vibration & No Heat Generation",
    judgement_criteria_hindi: "असामान्य आवाज/वाइब्रेशन / हीट जेनेरेशन न हो ",
  },
  {
    check_point_area_english: "Axis, Spindle drive belts tension.",
    check_point_area_hindi: "अकसीस, स्पीण्डल ड्राइव बेल्ट्स की टेंशन",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be loose",
    judgement_criteria_hindi: "ढीली न हो ",
  },
  {
    check_point_area_english:
      "Hyd. power pack, suction, inlet filter cleaning.",
    check_point_area_hindi: "हयड्रोलिक पावर पेक, सक्ष्ण, ईंलेट फ़िल्टर की सफाई ",
    checking_method: "Visual",
    judgement_criteria_english: "Should be free from Dust,Dirt",
    judgement_criteria_hindi: "धुल, गंदगी  न लगी हो, साफ होने चाहिए",
  },
  {
    check_point_area_english: "Electrical control panel cleaning.",
    check_point_area_hindi: "इलैक्ट्रिक पेनल की सफाई",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Disassemble and cleaning of the chuck.",
    check_point_area_hindi: "चक को खोलकर चक की  सफाई",
    checking_method: "Visual",
    judgement_criteria_english: "Should Function properly ",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Lubrication distribution oil flow checking.",
    check_point_area_hindi: "लुब्रिकेशन डिस्ट्रिब्यूशन ऑइल फलो",
    checking_method: "Visual",
    judgement_criteria_english: "Should flow smoothly",
    judgement_criteria_hindi: "स्मूथ फलो होना चाहिए ",
  },
  {
    check_point_area_english: "Check battery (system) voltage.",
    check_point_area_hindi: "बेटरी वोल्टेज चेक करना",
    checking_method: "Multimeter",
    judgement_criteria_english: "Standard requriment of CNC M/C",
    judgement_criteria_hindi: "Standard requriment of CNC M/C",
  },
  {
    check_point_area_english: "Electrical loose connections.",
    check_point_area_hindi: "ढीली तारें",
    checking_method: "visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए ",
  },
  {
    check_point_area_english: "Check smooth movement of axes.",
    check_point_area_hindi: "अकसीस की स्मूथ मूवमेंट ",
    checking_method: "Manual",
    judgement_criteria_english: "Should move Smoothly ",
    judgement_criteria_hindi: "स्मूथ काम करना चाहिए ",
  },
  {
    check_point_area_english: "Solenoid valve / power pack overheating.",
    check_point_area_hindi: "सोइलेनोइड वाल्व/ पावर पेक हीटिंग",
    checking_method: "Manual",
    judgement_criteria_english: "By Hand Touch ",
    judgement_criteria_hindi: "हाथ से छु कर ",
  },
  {
    check_point_area_english: "Machine level.",
    check_point_area_hindi: "मशीन का लेवेल ",
    checking_method: "Level Gauge ",
    judgement_criteria_english: "All Corner should Be at same level",
    judgement_criteria_hindi: "सभी कोर्नर सेम लेवल पर होने चाहिए ",
  },
  {
    check_point_area_english: "Hydraulic Oil Change & Tank Clean",
    check_point_area_hindi: "हाईड्रौलिक ऑइल चेंज व tank की सफाई",
    checking_method: "Manual",
    judgement_criteria_english: "Up To upper limit/Free From Dust,Rust",
    judgement_criteria_hindi: "अप्पर लिमिट तक /धूल व जंग नही लगा होना चाहिए ",
  },
  {
    check_point_area_english: "Emergency Switch",
    check_point_area_hindi: "एमेर्जेंसी स्विच",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Poke-yoke (If Applicable)",
    check_point_area_hindi: "पोका योके (यदि कोई है )",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "check feedback cable ",
    check_point_area_hindi: "फीडबेक केबल को चेक करना",
    checking_method: "Manual",
    judgement_criteria_english: "should tight properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Clean Drive Fan Properly",
    check_point_area_hindi: "ड्राइव फ़ैन को चेक करें",
    checking_method: "Manual",
    judgement_criteria_english: "Should Clean Properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Check Turret Locking Properly",
    check_point_area_hindi: "टुर्रेट लोक्किंग व कंडिशन  चेक करें",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly ",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Check SoloNight Valve",
    check_point_area_hindi: "सोलेनोइड वाल्व चेक करना",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly ",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Check all Oil Coatege",
    check_point_area_hindi: "Check all Oil Coatege",
    checking_method: "Manual",
    judgement_criteria_english: "Should work & Clean properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english:
      "Check Turret Condition and Change Bearing or Seal Kit in every P.M (It is applicable for only MAZAK CNC M/C)",
    check_point_area_hindi:
      "Check Turret Condition and Change Bearing or Seal Kit in every P.M (It is applicable for only MAZAK CNC M/C)",
    checking_method: "Manual",
    judgement_criteria_english: "Should work & Clean properly",
    judgement_criteria_hindi: "Should work & Clean properly",
  },
  {
    check_point_area_english:
      "Check Turret Coupling.(It is applicable for only LMW Machines)",
    check_point_area_hindi:
      "Check Turret Coupling.(It is applicable for only LMW Machines)",

    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए",
  },
  {
    check_point_area_english:
      "Chuck Rotary oil cylinder O Ring.(Change Every 2 Year).",
    check_point_area_hindi:
      "चक का रोटरी ऑइल सिलिंडर का ओ रिंग (हर 2 साल में बदलें)",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए",
  },
  {
    check_point_area_english:
      "Turret ReConditioning and check all Bearing (Bearing Change Every Year).",
    check_point_area_hindi: "टुर्रेट के बेयरिंग हर साल बदलें ",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए",
  },
  {
    check_point_area_english:
      "Check Chuck System Pressure & Maintain Min. 30 KG Pressure.(If not maintain the pressure during PM change the Hydraullic Pump).",
    check_point_area_hindi:
      "चक सिस्टम प्रैशर कम से कम 30 KG होना चाहिए (यदि कम है तो हाईड्रोलिक पम्प बदल दें )",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए",
  },
  {
    check_point_area_english: "Axis Belt teeth condition",
    check_point_area_hindi: "Axis Belt teeth condition",
    checking_method: "Manual",
    judgement_criteria_english: "Should not wear out",
    judgement_criteria_hindi: "Should not wear out",
  },
  {
    check_point_area_english: "Foot swith element and electric connection",
    check_point_area_hindi: "Foot swith element and electric connection",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly",
    judgement_criteria_hindi: "सही ढंग से काम करना  चाहिए",
  },
  {
    check_point_area_english: "Spindle fan to be clean",
    check_point_area_hindi: "Spindle fan to be clean",
    checking_method: "Manual",
    judgement_criteria_english: "Should be free from dust",
    judgement_criteria_hindi: "Should be free from dust",
  },
  {
    check_point_area_english: "Hydraullic pipe",
    check_point_area_hindi: "Hydraullic pipe",
    checking_method: "Manual",
    judgement_criteria_english: "Should not be damage",
    judgement_criteria_hindi: "Should not be damage",
  },
];

export const CTR = [
  {
    check_point_area_english: "Hydraulic Oil Power Pack Level ",
    check_point_area_hindi: "हयड्रोलिक ऑइल पेक लेवेल ",
    checking_method: "Visual",
    judgement_criteria_english: "Above Red  Mark (Above Lower limit)",
    judgement_criteria_hindi: "लाल निशान से ऊपर होना चाहिए ",
  },
  {
    check_point_area_english: "Check all Electric Connections for losseness",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning Of Electrical Panel ",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Manual ",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Hydraulic Oil change",
    check_point_area_hindi: "हयड्रोलिक ऑइल बदलना ",
    checking_method: "Manual ",
    judgement_criteria_english: "Up to Upper Limit",
    judgement_criteria_hindi: "अप्पर लिमिट के अनुसार ",
  },
  {
    check_point_area_english: "Hydraulic Tank cleaning ",
    check_point_area_hindi: "हयड्रोलिक टेंक की सफाई ",
    checking_method: "Visual",
    judgement_criteria_english: "Should be free from Dust,Rust ",
    judgement_criteria_hindi: "धुल न लगी हो,जंग न लगा हो",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "Condition Of Hydraulic Pipe",
    check_point_area_hindi: "हयड्रोलिक पाइप की कंडीशन ",
    checking_method: "Manual",
    judgement_criteria_english: "लीक नहीं करना चाहिए",
    judgement_criteria_hindi: "Should not leak",
  },
];
export const Drill_Machine = [
  {
    check_point_area_english: "Spindle Belt condition",
    check_point_area_hindi: "स्पीण्डल बेल्ट की कंडिशन ",
    checking_method: "Visual ",
    judgement_criteria_english: "Should not be Cracked & Damage ",
    judgement_criteria_hindi: "टूटे या देमेज न हो",
  },
  {
    check_point_area_english: "Spindle Belt Tension",
    check_point_area_hindi: "स्पीण्डल बेल्ट की टेंशन ",
    checking_method: "Visual",
    judgement_criteria_english: "Should not be Cracked & Damage ",
    judgement_criteria_hindi: "टूटे या डेमेज न हो",
  },
  {
    check_point_area_english: "Check All electrical Connection for looseness",
    check_point_area_hindi: "सभी इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning of electrical Panel",
    check_point_area_hindi: "इलैक्ट्रिक फेनल की सफाई ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Hydraulic Oil change",
    check_point_area_hindi: "हयड्रोलिक ऑइल बदलना ",
    checking_method: "Manual ",
    judgement_criteria_english: "Up to Upper Limit",
    judgement_criteria_hindi: "अप्पर लिमिट के अनुसार ",
  },
  {
    check_point_area_english: "Hydraulic Tank cleaning",
    check_point_area_hindi: "हयड्रोलिक टेंक की सफाई ",
    checking_method: "Visual",
    judgement_criteria_english: "Should be free from Dust,Rust ",
    judgement_criteria_hindi: "धुल न लगी हो,जंग न लगा हो",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "एमेर्जेंसी स्विच ",
    checking_method: "Manual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
];
export const Drill_Machine_Manual = [
  {
    check_point_area_english: "Inspect for leaks",
    check_point_area_hindi: "लीकेज ",
    checking_method: "Visual",
    judgement_criteria_english: "No leaks",
    judgement_criteria_hindi: "लीकेज नही होनी चाहिए ",
  },
  {
    check_point_area_english: "Inspect working area for safety hazards",
    check_point_area_hindi: "सेफटि इश्यू ",
    checking_method: "Visual",
    judgement_criteria_english: "Check environment",
    judgement_criteria_hindi: "मशीन चेक करें ",
  },
  {
    check_point_area_english: "Grease all grease points",
    check_point_area_hindi: "ग्रीसिंग सभी जगह पर जहां जरूरत है ",
    checking_method: "Visual",
    judgement_criteria_english: "Must Function Properly ",
    judgement_criteria_hindi: "फंकशन सही ढंग से काम करना  चाहिए ",
  },
  {
    check_point_area_english: "Electric wire condition",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: " Check feed cable/feed chain adjustment",
    check_point_area_hindi: "फीड केबल / फीड चेन की एडजस्टमेंट ",
    checking_method: "Manual",
    judgement_criteria_english: "Shoud be tight and no loose",
    judgement_criteria_hindi: "टाइट होना चाहिए ",
  },
];
export const Generator = [
  {
    check_point_area_english: "Temperature inside the control panel",
    check_point_area_hindi: "Temperature inside the control panel",
    checking_method: "Display Monitor",
    judgement_criteria_english: "",
    judgement_criteria_hindi: "",
  },
  {
    check_point_area_english: "Fuel oil level",
    check_point_area_hindi: "Fuel oil level",
    checking_method: "visual",
    judgement_criteria_english: "Should be free from dust,Dirt,Damage",
    judgement_criteria_hindi: "Should be free from dust,Dirt,Damage",
  },
  {
    check_point_area_english: " Check battery (system) voltage.",
    check_point_area_hindi: " Check battery (system) voltage.",
    checking_method: "Multimeter ",
    judgement_criteria_english: "Standard requriment of DG M/C",
    judgement_criteria_hindi: "Standard requriment of DG M/C",
  },
  {
    check_point_area_english: "Cleaning Of Panel.",
    check_point_area_hindi: "Cleaning Of Panel.",
    checking_method: "Manual",
    judgement_criteria_english: "Should be free from dust,Dirt,Damage",
    judgement_criteria_hindi: "Should be free from dust,Dirt,Damage",
  },
  {
    check_point_area_english: "Electrical loose connections.",
    check_point_area_hindi: "Electrical loose connections.",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
  },
  {
    check_point_area_english: "oil Level",
    check_point_area_hindi: "oil Level",
    checking_method: "visual",
    judgement_criteria_english: "",
    judgement_criteria_hindi: "",
  },
  {
    check_point_area_english:
      "Check for any cable breakage / damage shielding.",
    check_point_area_hindi: "Check for any cable breakage / damage shielding.",
    checking_method: "Visual",
    judgement_criteria_english: "No Breakage & damaged Shield ",
    judgement_criteria_hindi: "No Breakage & damaged Shield ",
  },
  {
    check_point_area_english: "Lubricant Oil",
    check_point_area_hindi: "Lubricant Oil",
    checking_method: "Visual",
    judgement_criteria_english: "",
    judgement_criteria_hindi: "",
  },
  {
    check_point_area_english: "Electrical control panel cleaning.",
    check_point_area_hindi: "Electrical control panel cleaning.",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "Should be free from Dust,rust & should not be wet",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "Emergency Switch ",
    checking_method: "Manual",
    judgement_criteria_english: "Should work properly ",
    judgement_criteria_hindi: "Should work properly ",
  },
  {
    check_point_area_english: "Rediator Belt ",
    check_point_area_hindi: "Rediator Belt ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should not be Cracked & Damage,Should Not be loose ",
    judgement_criteria_hindi:
      "Should not be Cracked & Damage,Should Not be loose ",
  },
  {
    check_point_area_english: "Alternator Belt ",
    check_point_area_hindi: "Alternator Belt ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Should not be Cracked & Damage,Should Not be loose ",
    judgement_criteria_hindi:
      "Should not be Cracked & Damage,Should Not be loose ",
  },
  {
    check_point_area_english: "Rediator Collant",
    check_point_area_hindi: "Rediator Collant",
    checking_method: "Visual",
    judgement_criteria_english: "Should Not Leaked ",
    judgement_criteria_hindi: "Should Not Leaked ",
  },
  {
    check_point_area_english: "Rediator Leakage ",
    check_point_area_hindi: "Rediator Leakage ",
    checking_method: "Visual",
    judgement_criteria_english: "Should Not Leaked ",
    judgement_criteria_hindi: "Should Not Leaked ",
  },
  {
    check_point_area_english: "Check Air Filter ",
    check_point_area_hindi: "Check Air Filter ",
    checking_method: "Visual",
    judgement_criteria_english: "No Dust, Should Clean Proper ",
    judgement_criteria_hindi: "No Dust, Should Clean Proper ",
  },
  {
    check_point_area_english: "Rediator Cleaning",
    check_point_area_hindi: "Rediator Cleaning",
    checking_method: "Visual",
    judgement_criteria_english: "No Dust, Should Clean Proper ",
    judgement_criteria_hindi: "No Dust, Should Clean Proper ",
  },
  {
    check_point_area_english: "Nut And Bolt ",
    check_point_area_hindi: "Nut And Bolt ",
    checking_method: "Spannar ",
    judgement_criteria_english: "No loose , And No Nut bolt missing ",
    judgement_criteria_hindi: "No loose , And No Nut bolt missing ",
  },
];
export const HP = [
  {
    check_point_area_english: "Spindle Belt condition",
    check_point_area_hindi: "Spindle Belt condition",
    checking_method: "Visual ",
    judgement_criteria_english: "Should not be Cracked & Damage ",
    judgement_criteria_hindi: "Should not be Cracked & Damage ",
  },
  {
    check_point_area_english: "Spindle Belt Tension",
    check_point_area_hindi: "Spindle Belt Tension",
    checking_method: "Visual ",
    judgement_criteria_english: "Should not be Cracked & Damage ",
    judgement_criteria_hindi: "Should not be Cracked & Damage ",
  },
  {
    check_point_area_english: "Check All electrical Connection for looseness",
    check_point_area_hindi: "सभी इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual ",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning of electrical Panel",
    check_point_area_hindi: "इलैक्ट्रिक पेनल की सफाई ",
    checking_method: "Visual ",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Hydraulic Oil change",
    check_point_area_hindi: "Hydraulic Oil change",
    checking_method: "Manual ",
    judgement_criteria_english: "Up to Upper Limit",
    judgement_criteria_hindi: "Up to Upper Limit",
  },
  {
    check_point_area_english: "Hydraulic Tank cleaning",
    check_point_area_hindi: "हयड्रोलिक टेंक की सफाई ",
    checking_method: "Visual ",
    judgement_criteria_english: "Should be free from Dust,Rust ",
    judgement_criteria_hindi: "धुल न लगी हो,जंग न लगा हो",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "Emergency Switch ",
    checking_method: "Manual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "Must function properly",
  },
  {
    check_point_area_english: "सोलेनोइड वाल्व",
    check_point_area_hindi: "सही ढंग से काम करना चाहिए ",
    checking_method: "Visual ",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "FRL वाल्व",
    check_point_area_hindi: "FRL वाल्व",
    checking_method: "Visual ",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "pneumatic सिलिंडर",
    check_point_area_hindi: "pneumatic सिलिंडर",
    checking_method: "Manual ",
    judgement_criteria_english: "पानी, सील बदलें ",
    judgement_criteria_hindi: "पानी, सील बदलें ",
  },
];
export const Hydrau = [
  {
    check_point_area_english: "Hydraulic Oil Power Pack Level ",
    check_point_area_hindi: "हयड्रोलिक ऑइल पेक लेवेल ",
    checking_method: "Visual",
    judgement_criteria_english: "Above Red  Mark (Above Lower limit)",
    judgement_criteria_hindi: "लाल निशान से ऊपर होना चाहिए ",
  },
  {
    check_point_area_english: "Check all Electric Connections for losseness",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual",
    judgement_criteria_english:
      "Electrical Wire Should Not be damaged ,Open & should not be loose  ",
    judgement_criteria_hindi: "तारें टूटी, ढीली या खुली नही होनी चाहिए  ",
  },
  {
    check_point_area_english: "Cleaning Of Electrical Panel ",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Manual ",
    judgement_criteria_english:
      "Should be free from Dust,rust & should not be wet",
    judgement_criteria_hindi:
      "धुल न लगी हो,जंग न लगा हो व गीला नही होना चाहिए ",
  },
  {
    check_point_area_english: "Hydraulic Oil change",
    check_point_area_hindi: "हयड्रोलिक ऑइल बदलना ",
    checking_method: "Manual",
    judgement_criteria_english: "Up to Upper Limit",
    judgement_criteria_hindi: "अप्पर लिमिट के अनुसार ",
  },
  {
    check_point_area_english: "Hydraulic Tank cleaning ",
    check_point_area_hindi: "हयड्रोलिक टेंक की सफाई ",
    checking_method: "Visual",
    judgement_criteria_english: "Should be free from Dust,Rust ",
    judgement_criteria_hindi: "धुल न लगी हो,जंग न लगा हो",
  },
  {
    check_point_area_english: "Emergency Switch ",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual",
    judgement_criteria_english: "Must function properly",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
];
export const IH = [
  {
    check_point_area_english: "हीट एक्ष्चंगर प्लेट चेक और गॅस किट चेक",
    check_point_area_hindi: "हीट एक्ष्चंगर प्लेट चेक और गॅस किट चेक",
    checking_method: "Visual ",
    judgement_criteria_english: "साफ होना चाहिए",
    judgement_criteria_hindi: "साफ होना चाहिए",
  },
  {
    check_point_area_english: "इलैक्ट्रिक तारों के कनैक्शन ",
    check_point_area_hindi: "इलैक्ट्रिक तारों के कनैक्शन ",
    checking_method: "Visual ",
    judgement_criteria_english: "नो लूस वाइर",
    judgement_criteria_hindi: "नो लूस वाइर",
  },
  {
    check_point_area_english: "इलैक्ट्रिक  पैनल की सफाई",
    check_point_area_hindi: "इलैक्ट्रिक  पैनल की सफाई",
    checking_method: "Visual ",
    judgement_criteria_english: "साफ होना चाहिए",
    judgement_criteria_hindi: "साफ होना चाहिए",
  },
  {
    check_point_area_english: "मोटर की कंडिशन",
    check_point_area_hindi: "मोटर की कंडिश",
    checking_method: "Visual ",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "एमर्जेंसी स्विच",
    check_point_area_hindi: "एमर्जेंसी स्विच",
    checking_method: "Manual ",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "बेल्ट की कंडिशन",
    check_point_area_hindi: "बेल्ट की कंडिशन",
    checking_method: "Visual ",
    judgement_criteria_english: "बेल्ट टाइट हो",
    judgement_criteria_hindi: "बेल्ट टाइट हो",
  },
  {
    check_point_area_english: "बेअरिंग की कंडीशन ",
    check_point_area_hindi: "बेअरिंग की कंडीशन ",
    checking_method: "Manual ",
    judgement_criteria_english: "सही ढंग से काम करना चाहिए ",
    judgement_criteria_hindi: "सही ढंग से काम करना चाहिए ",
  },
  {
    check_point_area_english: "क्लीनिंग ऑफ़ हीट एक्सचेंजर",
    check_point_area_hindi: "क्लीनिंग ऑफ़ हीट एक्सचेंजर",
    checking_method: "Manual",
    judgement_criteria_english: "फ्री ऑफ़ कार्बन ",
    judgement_criteria_hindi: "फ्री ऑफ़ कार्बन ",
  },
];
