import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import TopicPaperHtmlTable from "./TopicPaperHtmlTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const QuickViewTopicPaper = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [topicPaperData, setTopicPaperData] = useState();

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneTopicPaper/${id}`)
        .then((res) => {
          setTopicPaperData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  return (
    <div className={classes.root} style={{ position: "relative" }}>
      <TopicPaperHtmlTable {...{topicPaperData}}/>
    </div>
  );
};

export default QuickViewTopicPaper;
