import { Button, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import lodash from "lodash";
import { DatePicker, TimePicker } from "@material-ui/pickers";

import { Wrapper } from "../../../components/Control/Flexbox";
import formatDate from "../../../components/Control/formatDate";
import { useQuery } from "../../../components/Control/useQuery";
import TableReadEditCell from "../../../components/TableEditCell";

import TableScroll from "../../../components/TableScroll";
import { images, textInputs } from "../../../constants/constants";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { MoniteringSheetTableHead } from "./util";
const Table = Styled.table`
    width:98%;
    margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }

    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }

`;

function calculateWorkingHour(time1, time2) {
  if (!time1 && !time2) return "";
  if (new Date(time2).getTime() / 1000 <= new Date(time1).getTime() / 1000) {
    time2 = new Date(time2).setDate(new Date(time2).getDate() + 1);
  }
  const msInHour = 1000 * 60 * 60;
  let workingHour = (new Date(time2) - new Date(time1)) / msInHour;
  if (isNaN(workingHour)) {
    return "";
  }
  let timeArr = workingHour.toString().split(".");
  let hour = timeArr[0];
  let min = 0;
  if (timeArr[1]) {
    min = `${Math.ceil((+timeArr[1].slice(0, 2) / 100) * 60)}`.padStart(2, 0);
  }
  if (min == 60) {
    min = 0;
  }
  return `${hour} hr ${min} min`;
}

const MonitoringAddEditView = () => {
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const unit = query.get("unit");
  const [compRender, setCompRender] = useState(false);
  const queryAnnualDate = query.get("annual_date");
  const annualDate = new Date(queryAnnualDate);
  const [monitoringData, setMonitoringData] = useState({
    annual_date: annualDate,
    requestFormList: [],
  });
  const [render, setRender] = useState(false);
  const [render2, setRender2] = useState(false);

  useEffect(() => {
    (async () => {
      const requestForms = {};
      let requestFormRes = await axios
        .get(`/api/getRequestFormByMonthly/${annualDate}?unit=${unit}`)
        .catch((err) => console.log(err));
      if (requestFormRes?.data) {
        requestFormRes.data.map((request) => {
          requestForms[request.requestform_id._id] = {
            ...request,
            request_id: request.requestform_id?._id,
          };
        });
      }
      let monitoringRes = await axios
        .get(`/api/get4mMonitoringById/${queryAnnualDate}?unit=${unit}`)
        .catch((err) => console.log(err));
      if (monitoringRes?.data) {
        let values = lodash.cloneDeep(monitoringRes.data);
        monitoringRes.data?.requestFormList.forEach((request, index) => {
          if (!request.requestform_id) {
            values.requestFormList.splice(index, 1);
            return;
          }
          if (request.requestform_id?._id in requestForms) {
            // remove id from requestForms
            delete requestForms[request.requestform_id._id];
          } else {
            values.requestFormList.splice(index, 1);
          }
        });
        for (let key in requestForms) {
          values.requestFormList.push(requestForms[key]);
        }
        setMonitoringData(values);
        return;
      }
      setMonitoringData({
        annual_date: queryAnnualDate,
        requestFormList: lodash.cloneDeep(requestFormRes.data),
      });
    })();
  }, [render2]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (index === 0 || index) {
      const values = { ...monitoringData };
      values.requestFormList[index][name] = value;
      setMonitoringData(values);
    }
  };

  const onSubmit = () => {
    let isSubmit = window.confirm(
      `Are you sure you want to ${
        monitoringData._id ? "update" : "submit"
      } this document?`
    );
    if (!isSubmit) return;
    monitoringData.unit = unit;
    if (monitoringData._id) {
      axios
        .put(`/api/edit4mMonitoring/${monitoringData._id}`, monitoringData)
        .then((res) => {
          setRender2((prev) => !prev);
          if (res.data) alert("updated successfully");
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post("/api/create4mMonitoring", monitoringData)
        .then(async (res) => {
          if (res.data) {
            setRender2((prev) => !prev);
            alert("submitted successfully");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <main>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M MONITORING SHEET
        </Typography>
        <Wrapper>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(true);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#064a29",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            {monitoringData._id ? "Save" : "Submit"}
          </Button>
        </Wrapper>
      </div>
      <TableScroll style={{ marginTop: "2rem", margin: "1rem" }}>
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="26"
                rowSpan={3}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th>Doc. No:-</th>
              <td>F-PROD-28</td>
            </tr>
            <tr>
              <th>REV.No.-</th>
              <td> 06</td>
            </tr>
            <tr>
              <th>DATE:</th>
              <td> 01.04.2023</td>
            </tr>
            <tr>
              <th
                colSpan="28"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                4M CHANGE CONTROL
              </th>
            </tr>
          </thead>
          <tr className="middleText">
            {MoniteringSheetTableHead.map((head) => (
              <th
                rowSpan={head.rowspan}
                colSpan={head.colspan}
                style={{ textTransform: "uppercase" }}
              >
                {head.name}
              </th>
            ))}
          </tr>
          <tr className="middleText">
            <th>From</th>
            <th>Till</th>
            <th>Old</th>
            <th>New</th>
            <th>Inform to QA</th>
            <th>Set up approval</th>
            <th>Training Provided</th>
            <th>Retroactive</th>
            <th>Suspected</th>

            <th>Qty produced</th>
            <th>OK Qty</th>
            <th>Rej. Qty</th>

            <th>Qty produced</th>
            <th>Ok Qty</th>
            <th>Rej. Qty</th>

            <th>Customer </th>
            <th>Heat No</th>
            <th>Invoice No</th>
            <th>Remarks</th>
          </tr>

          <tbody className="tablebody-row">
            {monitoringData.requestFormList.map((requestForm, index) => (
              <tr>
                <td>{formatDate(requestForm.requestform_id?.request_date)}</td>
                <td
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "100px",
                  }}
                >
                  <TimePicker
                    name="out_time"
                    style={{
                      backgroundColor: "#f8f9fa",
                      padding: "8px 5px",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={requestForm.out_time || null}
                    onChange={(date) => {
                      requestForm.out_time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "100px",
                  }}
                >
                  <TimePicker
                    name="in_time"
                    style={{
                      backgroundColor: "#f8f9fa",
                      padding: "8px 5px",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={requestForm.in_time || null}
                    onChange={(date) => {
                      requestForm.in_time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>

                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    minWidth: "90px",
                  }}
                >
                  {calculateWorkingHour(
                    requestForm?.in_time,
                    requestForm?.out_time
                  )}
                </td>

                <td>{requestForm?.requestform_id?.change_item}</td>
                <td>
                  {(requestForm?.requestform_id?.planned_change && "P") ||
                    (requestForm?.requestform_id?.unplanned_change && "UP") ||
                    (requestForm?.requestform_id?.abnormal_situations && "ABN")}
                </td>
                <td>{`${requestForm?.part_id?.part_name} / ${requestForm?.part_id?.part_number}`}</td>
                <td>{requestForm?.requestform_id?.detail_of_change_new}</td>
                <td>{requestForm?.requestform_id?.detail_of_change_old}</td>
                <td>
                  <TableReadEditCell
                    type="text"
                    name="inform_to_qa"
                    key="inform_to_qa"
                    value={requestForm.inform_to_qa}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  {requestForm.requestform_id?.first_setup_approval === true &&
                    "✓"}
                </td>

                <td>
                  {requestForm.requestform_id?.retroactive_check === true &&
                    "✓"}
                </td>

                <td>
                  {requestForm.requestform_id?.suspected_lot_insp === true &&
                    "✓"}
                </td>

                <td>
                  {requestForm.requestform_id?.suspected_lot_insp === true &&
                    "✓"}
                </td>

                <td>
                  <TableReadEditCell
                    type="number"
                    name="retroactive_qty_produced"
                    key="retroactive_qty_produced"
                    value={requestForm.retroactive_qty_produced}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="number"
                    name="retroactive_ok_qty"
                    key="retroactive_ok_qty"
                    value={requestForm.retroactive_ok_qty}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="number"
                    name="retroactive_rej_qty"
                    key="retroactive_rej_qty"
                    value={requestForm.retroactive_rej_qty}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  <TableReadEditCell
                    type="number"
                    name="suspected_qty_produced"
                    key="suspected_qty_produced"
                    value={requestForm.suspected_qty_produced}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  <TableReadEditCell
                    type="number"
                    name="suspected_ok_qty"
                    key="suspected_ok_qty"
                    value={requestForm.suspected_ok_qty}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  <TableReadEditCell
                    type="number"
                    name="suspected_rej_qty"
                    key="suspected_rej_qty"
                    value={requestForm.suspected_rej_qty}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  <TableReadEditCell
                    type="text"
                    name="sig_prod_head"
                    key="sig_prod_head"
                    value={requestForm.sig_prod_head}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="number"
                    name="sig_qa_inspector"
                    key="sig_qa_inspector"
                    value={requestForm.sig_qa_inspector}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="text"
                    name="sig_qa_head"
                    key="sig_qa_head"
                    value={requestForm.sig_qa_head}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="text"
                    name="sig_maint_head"
                    key="sig_maint_head"
                    value={requestForm.sig_maint_head}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>{requestForm.part_id.customer}</td>
                <td>
                  <TableReadEditCell
                    type="number"
                    name="heat_no"
                    key="heat_no"
                    value={requestForm.heat_no}
                    {...{ handleChange, index, isView }}
                  />
                </td>
                <td>
                  <TableReadEditCell
                    type="number"
                    name="invoice_no"
                    key="invoice_no"
                    value={requestForm.invoice_no}
                    {...{ handleChange, index, isView }}
                  />
                </td>

                <td>
                  <TableReadEditCell
                    type="text"
                    name="remarks"
                    key="remarks"
                    value={requestForm.remarks}
                    {...{ handleChange, index, isView }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableScroll>
    </main>
  );
};

export default MonitoringAddEditView;
