import React from "react";
import { Link } from "react-router-dom";
import WorkIcon from "@material-ui/icons/Work";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import tilesStyle from "./tilesStyle.module.css";

const TNITiles = () => {
  return (
    <section style={{ height: "90vh" }}>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        TNI (TRAINING NEED IDENTIFICATION)
      </div>
      <main className={`${tilesStyle.container} ${tilesStyle.main}`}>
        <div>
          <Link to="topicPaper/list" className="panel">
            <WorkIcon style={{ fontSize: "3rem", color: "#a61e4d" }} />
          </Link>
          <p className="card-btn-para">
            SET <span className="card-btn-span">TNI TOPIC</span>
          </p>
        </div>
        <div>
          <Link to="/tniList/data" className="panel">
            <WorkIcon style={{ fontSize: "3rem", color: "#a61e4d" }} />
          </Link>
          <p className="card-btn-para">
            CREATE <span className="card-btn-span">TNI PLAN</span>
          </p>
        </div>
      </main>
    </section>
  );
};

export default TNITiles;
