import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/Description";

import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import TopicModal from "./TopicModal";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const TopicPaperList = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const questionAsPerDepartmentId = useRef("");
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllTopicPaper/list/?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render, isQuestionModal]);

  const handleDelete = async (id) => {
    const ans = await confirmDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/deleteTopicPaper/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          TNI TOPIC LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsQuestionModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Topic Paper
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "1" }}>
              <Input
                placeholder="Search By Name"
                variant="filled"
                style={{
                  width: "97%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                 Department 
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Add Topic 
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.topic_paper_name}
                      </TableCell>
                      <TableCell align="center">
                        {item?.area_of_work?.department_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                        }}
                      >
                        <div>
                          <Link
                            to={`/createEditTopicPaper/${item._id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                background: "#003566",
                                color: "#fafafa",
                              }}
                              startIcon={<DescriptionIcon />}
                            >
                              Add TNI Topics
                            </Button>
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() => {
                              setIsQuestionModal(true);
                              questionAsPerDepartmentId.current = item._id;
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="View Question Paper" arrow>
                          <Link to={`/quick_view_topic_paper/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.5rem",
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  marginLeft: "0.5rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Modal
          title={"create new paper"}
          openPopup={isQuestionModal}
          closeModal={() => {
            setIsQuestionModal(false);
            questionAsPerDepartmentId.current = "";
          }}
          backgroundColor="white"
        >
          <TopicModal
            setIsQuestionModal={setIsQuestionModal}
            id={questionAsPerDepartmentId.current}
          />
        </Modal>

        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default TopicPaperList;
