import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";

import { Paper } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Loader from "../../../components/Control/Loader";
import Visibility from "@material-ui/icons/Visibility";
import Modal from "../../../components/Control/Modal";
import ViewFile from "./ViewFile";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Delete, Save } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const getDateOfTraining = (date, month) => {
  console.log(month);
  const year = new Date(date).getFullYear();
  return `${months.indexOf(month) + 1}/${year}`;
};
const TrainingStatusList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [compRender, setCompRender] = useState(false);
  const idRef = useRef("");
  const processIndexRef = useRef("");
  const monthRef = useRef("");
  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAnnualTrainingForTrainingStatus/${selectedDate}`)
      .catch((err) => {
        console.log(err);
      });
    if (res.data) {
      setData(res.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, [render, selectedDate, isOpenPopup]);
  const handleUpload = (e, id, processIndex, month) => {
    if (!window.confirm("Are you sure you want upload this Files")) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append("files", files[key]);
    }
    setIsLoading(true);
    axios
      .patch(
        `/api/uploadTrainingStatusFile/${id}/${processIndex}/${month}`,
        formData
      )
      .then((res) => {
        if (res.data) {
          alert("document upload successly");
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const deleteDocument = async (id, processIndex, month) => {
    if (!window.confirm("Are you sure you want to delete this document?")) {
      return;
    }
    axios
      .delete(`/api/deleteTrainingStatus/${id}/${processIndex}/${month}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateTrainingStatus = (id, processIndex, month, isSubmitted) => {
    if (!window.confirm("Are you sure you want to update status?")) {
      return;
    }
    axios
      .patch(`/api/updateTrainingStatus/${id}/${processIndex}/${month}`, {
        isSubmitted: isSubmitted,
      })
      .then((res) => {
        if (res.data) {
          alert("status updated successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          TRAINING STATUS
        </div>
        <TableContainer component={Paper}>
          <div style={{ display: "flex", margin: "1rem" }}>
            <DatePicker
              views={["year"]}
              label="Year"
              className="date-field"
              inputVariant="filled"
              value={selectedDate || null}
              onChange={setSelectedDate}
            />
          </div>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Department Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Topic of Training
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Trainer
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Planned Hrs
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date of Training
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Attach Proof
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) =>
                  item.processes.map((process, processIndex) =>
                    Object.keys(process.training_months).map(
                      (month, monthIndex) =>
                        process.training_months[month].isChecked && (
                          <React.Fragment key={item._id}>
                            <TableRow>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                {item.departments.department_name}
                              </TableCell>
                              <TableCell align="center">
                                {process.training_topic}
                              </TableCell>
                              <TableCell align="center">
                                {process.faculty?.faculty_name}
                              </TableCell>
                              <TableCell align="center">
                                {process.participant}
                              </TableCell>
                              <TableCell align="center">
                                {getDateOfTraining(item.created_year, month)}
                              </TableCell>
                              <TableCell style={{ width: "240px" }}>
                                <Wrapper justify="space-between">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      id={`contained-button-file${
                                        index + processIndex + monthIndex
                                      }`}
                                      type="file"
                                      name="pcs_imageOrPdf"
                                      multiple
                                      accept="image/png,image/jpeg,image/jpg/,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      onChange={(e) =>
                                        handleUpload(
                                          e,
                                          item._id,
                                          processIndex,
                                          month
                                        )
                                      }
                                      hidden
                                    />
                                    <label
                                      htmlFor={`contained-button-file${
                                        index + processIndex + monthIndex
                                      }`}
                                      style={{
                                        marginBottom: "0",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        component="span"
                                        color="secondary"
                                      >
                                        <CloudUpload
                                          style={{ marginRight: "1rem" }}
                                        />
                                        Attach Proof
                                      </Button>
                                    </label>
                                  </div>
                                  {process.training_months[month].files.length >
                                    0 && (
                                    <Tooltip title="View File" arrow>
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          idRef.current = item._id;
                                          processIndexRef.current =
                                            processIndex;
                                          monthRef.current = month;
                                          setIsOpenPopup(true);
                                        }}
                                      >
                                        <Visibility
                                          style={{
                                            fontSize: "1.4rem",
                                            color: "#003566",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Wrapper>
                              </TableCell>
                              <TableCell align="center">
                                <select
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "0.9rem",
                                    outline: "none",
                                    border: "none",
                                    padding: "5px",
                                    marginLeft: "0.5rem",
                                    width: "10rem",
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                    ...(process.training_months[month]
                                      .isSubmitted === "Pending" && {
                                      backgroundColor: "#FDD835",
                                    }),
                                    ...(process.training_months[month]
                                      .isSubmitted === "Completed" && {
                                      backgroundColor: "#2b8a3e",
                                      color: "#fff",
                                    }),
                                    ...(process.training_months[month]
                                      .isSubmitted === "Overdue" && {
                                      backgroundColor: "#c80202",
                                      color: "#fff",
                                    }),
                                  }}
                                  name="isSubmited"
                                  value={
                                    process.training_months[month].isSubmitted
                                  }
                                  onChange={(e) => {
                                    process.training_months[month].isSubmitted =
                                      e.target.value;
                                    setCompRender((prev) => !prev);
                                  }}
                                >
                                  <option value="Pending">PENDING</option>
                                  <option value="Overdue">OVERDUE</option>
                                  <option value="Completed">COMPLETED</option>
                                </select>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  component="span"
                                  onClick={() =>
                                    updateTrainingStatus(
                                      item._id,
                                      processIndex,
                                      month,
                                      process.training_months[month].isSubmitted
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#064a29",
                                    color: "white",
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  UPDATE STATUS
                                </Button>
                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      deleteDocument(
                                        item._id,
                                        processIndex,
                                        month
                                      )
                                    }
                                  >
                                    <Delete
                                      style={{
                                        fontSize: "1.4rem",
                                        color: "#c80202",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                    )
                  )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Modal
          openPopup={isOpenPopup}
          title="File List"
          closeModal={(e) => setIsOpenPopup(false)}
        >
          <ViewFile
            id={idRef.current}
            processIndex={processIndexRef.current}
            month={monthRef.current}
          />
        </Modal>
      </main>
    </>
  );
};

export default TrainingStatusList;
