import React from "react";
import { Link } from "react-router-dom";
import tiles from "./tiles.module.css";
//icons
import BallotIcon from "@material-ui/icons/Ballot";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import ListIcon from "@material-ui/icons/List";
// end icons

const UnitsTiles = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        4M Management
      </div>
      <main className={`${tiles.container} ${tiles.main}`}>
        <div>
          <Link to="/ModulesTiles4M?unit=unit1" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            UNIT <span className="card-btn-span">1</span>
          </p>
        </div>
        <div>
          <Link to="/ModulesTiles4M?unit=unit2" className="panel">
            <BallotIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            UNIT<span className="card-btn-span">2</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default UnitsTiles;
