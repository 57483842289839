import React, { useState } from "react";
import {
  Table,
  // TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Modal from "../../../components/Control/Modal";
import SelectChecklist from "./SelectChecklist";
import axios from "axios";
import useConfirm from "../../../components/Control/useConfirm";
import useDebounce from "../../../components/Control/useDebounce";
import { Pagination } from "@material-ui/lab";
import { Add, Search } from "@material-ui/icons";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import formatDate from "../../../components/Control/formatDate";
import { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SyncAltIcon from "@material-ui/icons/SyncAlt";

function getCurrentStatus(status, dueDate) {
  const diff = new Date(`1-${dueDate}`) - new Date();
  if (status === "pending" && diff < 0) {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: "#e03131", color: "white" }}
      >
        MAINTENANCE OVERDUE
      </TableCell>
    );
  }

  if (status === "pending") {
    return (
      <TableCell align="center" style={{ backgroundColor: "#fcc419" }}>
        MAINTENANCE NOT STARTED
      </TableCell>
    );
  }

  if (status === "completed") {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: "#2f9e44", color: "white" }}
      >
        COMPLETED
      </TableCell>
    );
  }
  if (status === "started") {
    return (
      <TableCell align="center" style={{ backgroundColor: "#5c7cfa" }}>
        MAINTENANCE ONGOING
      </TableCell>
    );
  }
}

const ChecklistActionList = () => {
  const history = useHistory();

  const [isNewSelectChecklistModal, setIsNewSelectChecklistModal] =
    useState(false);
  const [checklistData, setChecklistData] = useState([]);
  const checkListCurrentID = useRef("");

  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this checklist?"
  );
  const [DialogChange, confirmChange] = useConfirm(
    "Change Machine",
    "Are you sure you want to change machine?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllChecklist?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setChecklistData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    isNewSelectChecklistModal,
  ]);

  async function removeChecklist(id) {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      await axios.delete(`/api/deleteChecklist/${id}`);
      setRender((prev) => !prev);
    }
  }

  const handleClearChecklistTable = async (machineid) => {
    const isClear = await confirmChange();
    if (isClear) {
      axios
        .get(`/api/getChecklistById/${machineid}`)
        .then((res) => {
          if (res.data) {
            let data = res.data;
            data.checkListMachineData = {
              Air_Compressor: [],
              Centr_Grinding: [],
              Centreless_Gr: [],
              CNC: [],
              CTR: [],
              Drill_Machine: [],
              Drill_Machine_Manual: [],
              Generator: [],
              HP: [],
              Hydrau: [],
              IH: [],
            };
            data.document_name = "";
            data.status = "pending";
            axios
              .put(`/api/updateChecklist/${machineid}`, data)
              .then((res) => {
                window.alert("updated successfully");
                setRender((prev) => !prev);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">CHECKLISTS</span>
        </p>
      </div>
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "1" }}>
            <Input
              placeholder="Search Machine Name"
              variant="filled"
              style={{
                width: "97%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Wrapper>
        <Table size="small">
          <TableHead>
            <TableRow style={{ padding: "6px" }}>
              {[
                "#",
                "Machine Name",
                "ID No.",
                "Maintenance Due Date",
                "Last Maintenance Date",
                "Status",
                "Action",
              ].map((item, index) => (
                <TableCell
                  key={index}
                  style={{ fontWeight: "bold" }}
                  align="center"
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checklistData.map((item, index) => (
              <TableRow>
                <TableCell align="center">
                  {" "}
                  {perPage * (page - 1) + index + 1}
                </TableCell>
                <TableCell align="center">{item.machine_name}</TableCell>
                <TableCell align="center">{item.machine_no}</TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {item.preventive_time}
                </TableCell>
                <TableCell align="center">
                  {formatDate(item.last_maintenance_date)}
                </TableCell>
                {getCurrentStatus(item.status, item.preventive_time)}
                <TableCell
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.document_name ? (
                    <Tooltip title="Change Machine" arrow>
                      <IconButton
                        size="small"
                        style={{
                          marginRight: ".5rem",
                        }}
                        onClick={(e) => {
                          handleClearChecklistTable(item._id);
                        }}
                      >
                        <SyncAltIcon
                          style={{ fontSize: "1.4rem", color: "#f03e3e" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        height: "28.4px",
                        width: "28.4px",
                        marginRight: ".5rem",
                      }}
                    ></div>
                  )}
                  <Tooltip title="View Checklist" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: ".5rem",
                      }}
                      onClick={() => {
                        if (item.document_name) {
                          history.push(
                            `/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=true`
                          );
                        }
                      }}
                    >
                      <VisibilityIcon
                        style={{ fontSize: "1.4rem", color: "#003566" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Checklist" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: ".5rem",
                      }}
                      onClick={() => {
                        if (item.document_name) {
                          history.push(
                            `/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=false`
                          );
                        } else {
                          checkListCurrentID.current = item._id;
                          setIsNewSelectChecklistModal(true);
                        }
                      }}
                    >
                      <Add style={{ fontSize: "1.4rem", color: "black" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Checklist" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "0.8rem",
                      }}
                      onClick={() => removeChecklist(item._id)}
                    >
                      <DeleteIcon
                        style={{
                          fontSize: "1.4rem",
                          color: "#c80202",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
      <Modal
        openPopup={isNewSelectChecklistModal}
        title="Select Checklist"
        closeModal={(e) => setIsNewSelectChecklistModal(false)}
      >
        <SelectChecklist checkListCurrentID={checkListCurrentID.current} />
      </Modal>
      <DialogDelete />
      <DialogChange />
    </>
  );
};

export default ChecklistActionList;
