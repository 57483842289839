import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Checkbox, TextField } from "@material-ui/core";

import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableScroll from "../../../components/TableScroll";
import { useQuery } from "../../../components/Control/useQuery";
import { useAuth } from "../../../context/GlobalContext";
import { Save } from "@material-ui/icons";
import formatDate from "../../../components/Control/formatDate";
import TableInput from "../../../components/Control/TableInput";
import ObservationInput from "./ObservationInput";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { formatDateForTime } from "../../../components/Control/formatDateForTime";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:96%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }

    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }

`;

function ViewSetupForm() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const { state } = useAuth();
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const unit = query.get("unit");
  const [render, setRender] = useState(false);
  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/request_form/${id}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);

  // const handleChange = (e, index, arrayName) => {
  //   if (index || index === 0) {
  //     const values = { ...data };
  //     values[arrayName][index][e.target.name] = e.target.value;
  //     setData(values);
  //   } else {
  //     const values = { ...data };
  //     values[e.target.name] = e.target.value;
  //     setData(values);
  //   }
  // };

  const onSubmit = (isSubmitted) => {
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Request Form?"
    );
    if (!isSubmit) {
      return;
    }

    data.is_setup_submitted = isSubmitted;
    if (isSubmitted) {
      data.is_admin_setup = "pending";
      data.admin_setup_no = 1;
    } else {
      data.is_admin_setup = "nothing";
    }
    data.setup_prepared_by = state.user.name;
    axios
      .put(`/api/edit/request_form/${id}`, data)
      .then((res) => {
        if (res.data.status === "success") {
          alert("updated successfully");
          // history.push(`/supervisor/view/4mTable?unit=${unit}`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M SETUP FORM
        </Typography>
        <Wrapper>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(false);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#003566",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Save & Continue
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(true);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#064a29",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Submit
          </Button>
        </Wrapper>
      </div>
      <TableScroll>
        <Table
          cellSpacing="0"
          style={{ marginTop: "0.6rem" }}
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="15, 23, 24, 23, 23, 30, 30, 15, 15, 13"
        >
          <thead>
            <tr data-height="80">
              <th
                colSpan={16}
                rowSpan={3}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>

              <th align="left">Doc No:- </th>
              <th align="left">F-QA-43</th>
            </tr>
            <tr>
              <th align="left">Rev No:- </th>
              <th align="left">00</th>
            </tr>
            <tr>
              <th align="left">Dated:- </th>
              <th align="left">01.10.2019</th>
            </tr>
            <tr data-height="40">
              <th
                colSpan={18}
                align="center"
                style={{
                  border: "1px solid black",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
                data-fill-color="FF000000"
              >
                4M CHANGE INSPECTION RECORDS
              </th>
            </tr>
            <tr>
              <th rowSpan={4}>Type Of Change</th>
              <th>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <p>Man</p>
                  <span
                    style={{
                      fontSize: "1rem",
                      marginLeft: "6rem",
                    }}
                  >
                    {data?.change_item === "Man" ? "☑" : "□"}
                  </span>
                </div>
              </th>
              <th rowSpan={4}>Category of Changes</th>
              <th colSpan={2}>Planned Change</th>
              <th>
                <Checkbox
                  name="planned_change"
                  color="primary"
                  checked={data.planned_change === true}
                  onChange={(e) => {
                    data.planned_change = e.target.checked;
                    data.unplanned_change = false;
                    data.abnormal_situations = false;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th rowSpan={4} colSpan={2}>
                Activity to be performed (As per Std)
              </th>
              <th colSpan={3}>First Setup Approval</th>
              <th>
                <Checkbox
                  name="first_setup_approval"
                  color="primary"
                  checked={data.first_setup_approval === true}
                  onChange={(e) => {
                    data.first_setup_approval = e.target.checked;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={3} rowSpan={2}>
                <Wrapper style={{ gap: "5px" }}>
                  4M Change Date & Time:-
                  {isView ? (
                    formatDateForTime(data.change_4m_time)
                  ) : (
                    <TimePicker
                      name="change_4m_time"
                      style={{
                        backgroundColor: "#F8DE7E",
                        padding: "8px 5px",
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={data.change_4m_time || null}
                      onChange={(date) => {
                        data.change_4m_time = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </Wrapper>
              </th>
              <th colSpan={2} rowSpan={2}>
                <Wrapper style={{ gap: "5px" }}>
                  Termination Date:-
                  {isView ? (
                    formatDate(data.change_4m_date)
                  ) : (
                    <DatePicker
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#F8DE7E",
                        paddingLeft: "25px",
                        width: "150px",
                      }}
                      value={data.change_4m_date}
                      onChange={(date) => {
                        data.change_4m_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </Wrapper>
              </th>
              <th colSpan={3} rowSpan={2}></th>
            </tr>
            <tr>
              <th>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <p>Machine</p>
                  <span
                    style={{
                      fontSize: "1rem",
                      marginLeft: "6rem",
                    }}
                  >
                    {data?.change_item === "M/c" ? "☑" : "□"}
                  </span>
                </div>
              </th>

              <th colSpan={2}>Unplanned Change</th>
              <th>
                <Checkbox
                  name="unplanned_change"
                  color="primary"
                  checked={data.unplanned_change === true}
                  onChange={(e) => {
                    data.unplanned_change = e.target.checked;
                    data.planned_change = false;
                    data.abnormal_situations = false;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={3}>Retroactive Check</th>
              <th>
                <Checkbox
                  name="retroactive_check"
                  color="primary"
                  checked={data.retroactive_check === true}
                  onChange={(e) => {
                    data.retroactive_check = e.target.checked;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
            </tr>
            <tr>
              <th>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Material</p>
                  <span
                    style={{
                      fontSize: "1rem",
                      marginLeft: "6rem",
                    }}
                  >
                    {data?.change_item === "Mate" ? "☑" : "□"}
                  </span>
                </div>
              </th>
              <th colSpan={2}>Abnormal Situations</th>
              <th>
                <Checkbox
                  name="abnormal_situations"
                  color="primary"
                  checked={data.abnormal_situations === true}
                  onChange={(e) => {
                    data.abnormal_situations = e.target.checked;
                    data.planned_change = false;
                    data.unplanned_change = false;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={3}>Suspected Lot Insp.</th>
              <th>
                <Checkbox
                  name="suspected_lot_insp"
                  color="primary"
                  checked={data.suspected_lot_insp === true}
                  onChange={(e) => {
                    data.suspected_lot_insp = e.target.checked;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={4} rowSpan={2}>
                4M Change Final Status{" "}
                <TableInput
                  isView={isView}
                  name="change_4m_final_status"
                  type="text"
                  value={data?.change_4m_final_status}
                  onChange={(e) => {
                    data.change_4m_final_status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={4} rowSpan={2}>
                <Wrapper style={{ gap: "5px" }}>
                  Termination Date:-
                  {isView ? (
                    formatDate(data.termination_date)
                  ) : (
                    <DatePicker
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#F8DE7E",
                        paddingLeft: "25px",
                        width: "150px",
                      }}
                      value={data.termination_date}
                      onChange={(date) => {
                        data.termination_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </Wrapper>
              </th>
            </tr>
            <tr>
              <th>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <p>Method</p>
                  <span
                    style={{
                      fontSize: "1rem",
                      marginLeft: "6rem",
                    }}
                  >
                    {data?.change_item === "Method" ? "☑" : "□"}
                  </span>
                </div>
              </th>

              <th colSpan={2}></th>
              <th></th>
              <th colSpan={3}>Training</th>
              <th>
                <Checkbox
                  name="training"
                  color="primary"
                  checked={data.training === true}
                  onChange={(e) => {
                    data.training = e.target.checked;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
            </tr>
            <tr>
              <th colSpan={3}>PART NAME: {data.part_id?.part_name}</th>
              <th colSpan={3}>PART NO: {data.part_id?.part_number}</th>

              <th colSpan={4}>
                Batch No. :
                <TableInput
                  isView={isView}
                  name="batch_no"
                  type="text"
                  value={data?.batch_no}
                  onChange={(e) => {
                    data.batch_no = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={6}>
                Heat No. :
                <TableInput
                  isView={isView}
                  name="heat_no"
                  type="text"
                  value={data?.heat_no}
                  onChange={(e) => {
                    data.heat_no = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={3}>
                Customer: {data.part_id?.customer_id?.customer}
              </th>
            </tr>
          </thead>
          <tbody className="tablebody-row">
            <tr className="middleText">
              <th colSpan={5}>SETUP APPROVAL RECORDS</th>
              <th colSpan={10}>OBSERVATIONS</th>
              <th colSpan={3}>RESULTS</th>
            </tr>
            <tr className="middleText">
              {[
                "S.NO",
                "PARAMETER",
                "SPECIFICATION / TOLERANCE",
                "METHOD",
                "SAMPLE SIZE",
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                "Judgment /Status",
                "Verified By",
                "Remarks",
              ].map((head, index) => (
                <th
                  key={head + index + "heading"}
                  style={{ textTransform: "uppercase" }}
                >
                  {head}
                </th>
              ))}
            </tr>
            {data?.setups &&
              data?.setups?.length > 0 &&
              data?.setups?.map((setup, setupInd) => {
                return setup?.setup_list?.map((spec, index) => (
                  <tr key={setupInd + index + "setup" + 10.1}>
                    <td>{index + 1}</td>
                    <td style={{ width: "200px", minWidth: "200px" }}>
                      {spec.parameter}
                    </td>
                    <td style={{ width: "200px", minWidth: "200px" }}>
                      {spec.specification}
                    </td>
                    <td style={{ width: "200px", minWidth: "200px" }}>
                      {spec.methods}
                    </td>
                    <td style={{ width: "150px", minWidth: "150px" }}>
                      <TableInput
                        isView={isView}
                        name="sample_size"
                        type="number"
                        defaultValue={spec.sample_size}
                        onChange={(e) => {
                          spec.sample_size = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>

                    {/* {Array.from({ length: 10 }, (_, obsIndex) => (
                      <ObservationInput
                        {...{ spec, isView, obsIndex }}
                        obsNumber={0}
                        checkType={spec.appearance}
                      />
                    ))} */}

                    {Array.from({ length: 10 }, (_, obsIndex) => (
                      <td style={{ width: "150px", minWidth: "150px" }}>
                        <TableInput
                          isView={isView}
                          name="obs"
                          type="text"
                          defaultValue={spec.check_0_results[obsIndex]}
                          onChange={(e) => {
                            spec.check_0_results[obsIndex] = e.target.value;
                            setRender((prev) => !prev);
                          }}
                        />
                      </td>
                    ))}
                    <td style={{ width: "150px", minWidth: "150px" }}>
                      <TableInput
                        isView={isView}
                        name="status"
                        type="text"
                        defaultValue={spec.status}
                        onChange={(e) => {
                          spec.status = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ width: "150px", minWidth: "150px" }}>
                      <TableInput
                        isView={isView}
                        name="verified_by"
                        type="text"
                        defaultValue={spec.verified_by}
                        onChange={(e) => {
                          spec.verified_by = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ width: "150px", minWidth: "150px" }}>
                      <TableInput
                        isView={isView}
                        name="remarks"
                        type="text"
                        defaultValue={spec.remarks}
                        onChange={(e) => {
                          spec.remarks = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  </tr>
                ));
              })}
          </tbody>
          <tfoot>
            <tr style={{ height: "60px" }} data-height="35">
              <td align="left" colSpan={2} style={{ fontWeight: 500 }}>
                Prepared By:
              </td>
              <td
                align="left"
                colSpan={6}
                style={{ textTransform: "capitalize" }}
              >
                {data.setup_prepared_by}
              </td>

              <td align="left" colSpan={5} style={{ fontWeight: 500 }}>
                Approved By:
              </td>
              <td
                align="left"
                style={{ textTransform: "capitalize" }}
                colSpan={5}
              >
                {data.is_admin_setup === "accepted" && data.setup_approved_by}
              </td>
            </tr>
          </tfoot>
        </Table>
      </TableScroll>
    </div>
  );
}

export default ViewSetupForm;
