import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@material-ui/core";
import lodash from "lodash";

import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { TableContainerStyled, Table } from "../Container.styled";
import DragableButton from "../../../components/Control/Dragable";
import { useQuery } from "../../../components/Control/useQuery";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const months = {
  april: {
    isChecked: false,
  },
  may: {
    isChecked: false,
  },
  june: {
    isChecked: false,
  },
  july: {
    isChecked: false,
  },
  august: {
    isChecked: false,
  },
  september: {
    isChecked: false,
  },
  october: {
    isChecked: false,
  },
  november: {
    isChecked: false,
  },
  december: {
    isChecked: false,
  },
  january: {
    isChecked: false,
  },
  february: {
    isChecked: false,
  },
  march: {
    isChecked: false,
  },
};

const CreateEditAnnalTraining = () => {
  const classes = dropdownStyles();
  const history = useHistory();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [annualTrainingData, setAnnualTrainingData] = useState({
    processes: [],
  });
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [autoCompletevalue, setAutocompleteValue] = useState([]);
  const [allFaculty, setAllFaculty] = useState([]);
  const [facultyAutocomplete, setFacultyAutocomplete] = useState([]);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "CLOSE",
    "Are you sure you want to save and modify this document?"
  );

  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    "Add Task",
    "Are you sure you want to add a Task?"
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    "Remove Task",
    "Are you sure you want to remove a Task?"
  );
  const [DialogRemoveRow, confirmDialogRemoveRow] = useConfirm(
    "Remove Row",
    "Are you sure you want to remove this row"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getAnnualTrainingById/${id}`)
        .then((res) => {
          setAnnualTrainingData(res.data);
          const facultyforAutoComplete = res.data.processes.map((item) => {
            if (item.faculty)
              return {
                faculty_id: item.faculty?._id,
                faculty_name: item.faculty?.faculty_name,
                faculty_type: item.faculty?.faculty_type,
              };
          });
          setFacultyAutocomplete(facultyforAutoComplete);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    if (annualTrainingData.processes.length === 0) {
      axios
        .get(`/api/getTopicByDepartment/${annualTrainingData.departments?._id}`)
        .then((res) => {
          if (res.data) {
            annualTrainingData.processes = Array.from(
              { length: res.data.topics.length },
              (_, index) => ({
                training_topic: res.data.topics[index]?.name,
                topic_id: res.data.topics[index]?._id,
                faculty: null,
                internal_external: "",
                participant: "",
                reamarks: "",
                training_months: lodash.cloneDeep(months),
                // ...res.data?.topics[index],
              })
            );
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/api/getTopicByDepartment/${annualTrainingData.departments?._id}`)
        .then((res) => {
          if (res.data) {
            const topicObj = {};
            res.data.topics.map((topic) => {
              topicObj[topic._id] = topic;
            });
            const temp = { ...annualTrainingData };
            temp.processes.map((process, pIdx) => {
              if (process.topic_id in topicObj) {
                annualTrainingData.processes[pIdx].training_topic =
                  topicObj[process.topic_id]?.name;
                delete topicObj[process.topic_id];
              } else {
                temp.processes.splice(pIdx, 1);
              }
            });
            for (let key in topicObj) {
              annualTrainingData.processes.push({
                training_topic: topicObj[key]?.name,
                topic_id: topicObj[key]?._id,
                faculty: null,
                internal_external: "",
                participant: "",
                reamarks: "",
                training_months: lodash.cloneDeep(months),
              });
            }
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [annualTrainingData]);
  useEffect(() => {
    axios
      .get(`/api/getFaculty/list`)
      .then((res) => {
        const faculty = res?.data?.results.map((item) => {
          return {
            faculty_id: item._id,
            faculty_name: item.faculty_name,
            faculty_type: item.faculty_type,
          };
        });
        setAllFaculty(faculty);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDoubleClick = async (event, month, idx) => {
    if (isView) return;
    if (event.detail === 2) {
      if (annualTrainingData.processes[idx].training_months[month].isChecked) {
        let ans = await confirmDialogRemoveTask();
        if (ans) {
          annualTrainingData.processes[idx].training_months[
            month
          ].isChecked = false;
          setRender((prev) => !prev);
        }
      } else {
        let ans = await confirmDialogAddTask();
        if (ans) {
          annualTrainingData.processes[idx].training_months[
            month
          ].isChecked = true;
          setRender((prev) => !prev);
        }
      }
    }
  };

  function MouseOver(event) {
    event.target.style.transform = "scale(1.2)";
    event.target.style.transition = "200ms";
    event.target.style.border = "1px solid #ced4da";
    event.target.style.borderRadius = "5px";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
  }
  function MouseOut(event) {
    event.target.style.transform = "scale(1)";
    event.target.style.border = "none";
    event.target.style.boxShadow = "none";
    event.target.style.transition = "200ms";
  }
  function MouseDown(event) {
    event.target.style.transform = "scale(1.1)";
    event.target.style.transition = "300ms";
    event.target.style.border = "1px solid #868e96";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
  }

  const handleSaveAndContinue = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    axios
      .put(`/api/updateAnnualTraining/${id}`, annualTrainingData)
      .then((res) => {
        window.alert("success");
        history.goBack();
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRow = async (idx) => {
    const ans = await confirmDialogRemoveRow();
    if (!ans) return;
    let data = { ...annualTrainingData };
    data.processes.splice(idx, 1);
    setAnnualTrainingData(data);
  };
  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      <DragableButton left>
        <ArrowBackIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <DragableButton right>
        <ArrowForwardIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          ANNUAL TRAINING PLAN FOR STAFF
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            {/* <Wrapper> */}
            {/*   <Wrapper */}
            {/*     style={{ */}
            {/*       height: "45px", */}
            {/*       width: "175px", */}
            {/*       backgroundColor: "#1864ab", */}
            {/*       color: "white", */}
            {/*       cursor: "pointer", */}
            {/*       display: "flex", */}
            {/*       gap: "0.5rem", */}
            {/*       marginRight: "0.2rem", */}
            {/*     }} */}
            {/*     onClick={() => { */}
            {/*       annualTrainingData && */}
            {/*         annualTrainingData.processes.push({ */}
            {/*           training_topic: "", */}
            {/*           faculty: null, */}
            {/*           internal_external: "", */}
            {/*           participant: "", */}
            {/*           reamarks: "", */}
            {/*           training_months: lodash.cloneDeep(months), */}
            {/*         }); */}
            {/*       setRender((prev) => !prev); */}
            {/*     }} */}
            {/*     justify="center" */}
            {/*   > */}
            {/*     <Add /> */}
            {/*     <span>ADD ROWS</span> */}
            {/*   </Wrapper> */}
            {/* </Wrapper> */}

            {!isView && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  handleSaveAndContinue();
                }}
              >
                Save And Continue
              </Button>
            )}
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="16"
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                colSpan={3}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No. F-HRD-05
              </th>
            </tr>
            <tr>
              <th
                colSpan={3}
                style={{ bo5er: "1px solid black" }}
                data-f-sz="10"
              >
                REV. NO./Date:- 01/01-03-2018
              </th>
            </tr>
            <tr>
              <th
                colSpan="19"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                {`ANNUAL TRAINING PLAN FOR STAFF  ${getYearRange(
                  annualTrainingData.created_year
                )}`}
              </th>
            </tr>

            <tr>
              <th colSpan="19">
                DEPARTMENT:
                {annualTrainingData?.departments?.department_name}
              </th>
            </tr>

            <tr>
              <th colspan="1" rowspan="2">
                S.NO.
              </th>
              <th colspan="1" rowspan="2">
                TOPIC OF TRAINING
              </th>
              <th colspan="1" rowspan="2">
                TRAINER
              </th>
              <th colspan="1" rowspan="2">
                INTERNAL/EXTERNAL
              </th>
              <th colspan="1" rowspan="2">
                PLANNED HOURS
              </th>
              <th colspan="12" rowspan="1">
                YEAR: {getYearRange(annualTrainingData.created_year)}
              </th>
              <th colspan="2" rowspan="2">
                REMARKS
              </th>
            </tr>

            <tr>
              {[
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
                "JAN",
                "FEB",
                "MAR",
              ].map((el, index) => (
                <th colSpan="1" align="center" style={{ textAlign: "center" }}>
                  {el}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {annualTrainingData.processes &&
              annualTrainingData.processes.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td width={"200px"}>{item.training_topic}</td>
                    <td width="150px">
                      {item.internal_external === "EXTERNAL" ? (
                        isView ? (
                          item?.faculty_name
                        ) : (
                          <input
                            autoComplete="off"
                            className="input"
                            name="faculty_name"
                            style={{
                              border: "1px solid #f9e0e0",
                              height: "50px",
                            }}
                            type="textfield"
                            defaultValue={item.faculty_name}
                            onChange={(e) => {
                              item.faculty_name = e.target.value;
                            }}
                          />
                        )
                      ) : isView ? (
                        item.faculty?.faculty_name
                      ) : (
                        <Autocomplete
                          id="training_topics"
                          options={allFaculty}
                          getOptionLabel={(option) => option.faculty_name}
                          getOptionSelected={(option, value) =>
                            option.faculty_name === value.faculty_name
                          }
                          value={
                            facultyAutocomplete[index] ||
                            item?.faculty?.faculty_name
                          }
                          onChange={(e, value) => {
                            facultyAutocomplete[index] = value;
                            value && (item.faculty = value.faculty_id);
                            value && (item.faculty_type = value.faculty_type);
                            setRender((prev) => !prev);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        />
                      )}
                    </td>
                    <td width="200px">
                      {item.faculty_type
                        ? item.faculty_type
                        : item.faculty?.faculty_type}
                    </td>
                    <td width="120px">
                      {isView ? (
                        item.participant
                      ) : (
                        <input
                          autoComplete="off"
                          className="input"
                          name="participant"
                          style={{
                            border: "1px solid #f9e0e0",
                            height: "50px",
                          }}
                          type="textfield"
                          defaultValue={item.participant}
                          onChange={(e) => {
                            item.participant = e.target.value;
                          }}
                        />
                      )}
                    </td>
                    {[
                      "april",
                      "may",
                      "june",
                      "july",
                      "august",
                      "september",
                      "october",
                      "november",
                      "december",
                      "january",
                      "february",
                      "march",
                    ].map((month) => (
                      <td
                        style={{
                          padding: "0px",
                          margin: "0px",
                          backgroundColor: "#f1f3f5",
                        }}
                        onClick={(e) => handleDoubleClick(e, month, index)}
                      >
                        <span
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onMouseDown={MouseDown}
                          style={{
                            fontSize: "3rem",
                            display: "flex",
                            outline: "none",
                            width: "60px",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#495057",

                            backgroundColor: "#f1f3f5",
                            boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
                            cursor: "pointer",
                            textShadow: "1px 1px #b0b0b0",
                          }}
                        >
                          {item.training_months[month]?.isChecked && "○"}
                        </span>
                      </td>
                    ))}
                    <td width="150px" colSpan={2}>
                      {isView ? (
                        item.remarks
                      ) : (
                        <input
                          autoComplete="off"
                          className="input"
                          name="remarks"
                          style={{
                            border: "1px solid #f9e0e0",
                            height: "50px",
                          }}
                          type="textfield"
                          defaultValue={item.remarks}
                          onChange={(e) => {
                            item.remarks = e.target.value;
                          }}
                        />
                      )}
                    </td>
                    {/* <td */}
                    {/*   style={{ */}
                    {/*     cursor: "pointer", */}
                    {/*     minWidth: "50px", */}
                    {/*   }} */}
                    {/* > */}
                    {/*   {!isView && ( */}
                    {/*     <Wrapper */}
                    {/*       justify="center" */}
                    {/*       onClick={(e) => { */}
                    {/*         handleDeleteRow(index); */}
                    {/*       }} */}
                    {/*     > */}
                    {/*       <Tooltip title="Delete this row" arrow> */}
                    {/*         <DeleteIcon style={{ color: " #f03e3e" }} /> */}
                    {/*       </Tooltip> */}
                    {/*     </Wrapper> */}
                    {/*   )} */}
                    {/* </td> */}
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
        <DialogAddTask isSubmitContent={false} />
        <DialogRemoveTask isSubmitContent={false} />
        <DialogClose isSubmitContent={false} />
        <DialogSave isSubmitContent={false} />
        <DialogRemoveRow isSubmitContent={false} />
      </TableContainerStyled>
    </div>
  );
};

export default CreateEditAnnalTraining;
