import React from "react";
import TableToExcel from "@dayalk/table-to-excel";
import {
  TableContainerStyled,
  Table,
} from "../../../components/Control/TabelStyles";
import { mdiFileExcel } from "@mdi/js";
import PrintIcon from "@material-ui/icons/Print";

import Icon from "@mdi/react";
import { Tooltip, Button } from "@material-ui/core";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import { Wrapper } from "../../../components/Control/Flexbox";

const TopicPaperHtmlTable = ({ topicPaperData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <main
      style={{
        paddingBottom: "4rem",
      }}
    >
      {/* <Wrapper */}
      {/*   style={{ */}
      {/*     position: "absolute", */}
      {/*     top: "0.2rem", */}
      {/*     right: "1rem", */}
      {/*     zIndex: "100000", */}
      {/*   }} */}
      {/* > */}
      {/*   <Tooltip arrow title="Print table"> */}
      {/*     <PrintIcon */}
      {/*       style={{ */}
      {/*         color: "white", */}
      {/*         cursor: "pointer", */}
      {/*         fontSize: "1.9rem", */}
      {/*         marginRight: "0.6rem", */}
      {/*       }} */}
      {/*       onClick={printTable} */}
      {/*     /> */}
      {/*   </Tooltip> */}
      {/*   <Tooltip arrow title="Export as spreadsheet"> */}
      {/*     <div> */}
      {/*       <Button */}
      {/*         style={{ */}
      {/*           color: "white", */}
      {/*           width: "fit-content", */}
      {/*         }} */}
      {/*         onClick={() => { */}
      {/*           TableToExcel.convert(document.getElementById("table-to-xls"), { */}
      {/*             name: `tests.xlsx`, */}
      {/*             sheet: { */}
      {/*               name: "pfd_diagram", */}
      {/*             }, */}
      {/*           }); */}
      {/*         }} */}
      {/*       > */}
      {/*         <Icon path={mdiFileExcel} size={1.2} /> */}
      {/*       </Button> */}
      {/*     </div> */}
      {/*   </Tooltip> */}
      {/* </Wrapper> */}
      <TableContainerStyled
        style={{ margin: "1rem", backgroundColor: "white" }}
      >
        <Table
          id="table-to-xls"
          cellSpacing="0"
          style={{
            borderCollapse: "collapse",
          }}
          data-default-wrap="true"
          data-cols-width="16, 9, 30, 9, 30, 20, 30"
        >
          <thead>
            <tr data-height="100">
              <th
                colSpan={3}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                  color: "#343a40",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              {["Topic No.", "Topic Type", "Topic Name"].map((item) => (
                <th
                  align="center"
                  width={item === "Topic Name" && "800px"}
                  height="50px"
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {topicPaperData &&
              topicPaperData.topics.map((item, index) => (
                <tr>
                  <td align="center">{item.s_no}</td>
                  <td align="center" height="50px">
                    {item.topic_type}
                  </td>
                  <td align="center" height="50px">
                    {item.name}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableContainerStyled>
    </main>
  );
};

export default TopicPaperHtmlTable;
