import {
  Table,
  TableHead,
  Box,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import axios from "axios";
import React, { useEffect, useState } from "react";

const ViewFile = ({ month, processIndex, id }) => {
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/getTrainingStatusById/${id}/${processIndex}/${month}`)
      .then((res) => {
        if (res.data) {
          setFiles(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [render]);
  const deleteFile = async (fileIndex) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(
        `/api/deleteTrainingStatusFile/${id}/${processIndex}/${month}/${fileIndex}`
      )
      .then((res) => {
        if (res.data) {
          alert("document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        {files && (
          <TableBody>
            {files.map((file, index) => (
              <React.Fragment key={file._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{file.name}</TableCell>
                  <TableCell align="center">
                    <>
                      <Tooltip title="View">
                        <a target={"_blank"} href={file.url}>
                          <IconButton size="small">
                            <Visibility
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </a>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={() => deleteFile(index)}
                        >
                          <Delete
                            style={{
                              fontSize: "1.4rem",
                              color: "#c80202",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  );
};

export default ViewFile;
